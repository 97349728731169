import React, { useEffect, useState } from "react";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";
import material_symbols_download from "../../Assets/QuizMobile/material_symbols_download.svg";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";
import { Modal } from "antd";
import checkImg from "../../Assets/QuizMobile/streamline-icon-rating-document@300x300 1.svg";
import HollowStarRating from "../../Assets/QuizMobile/HollowStarRating.svg";
import FullStarRating from "../../Assets/QuizMobile/FullStarRating.svg";
// import Rating1 from "../../Assets/QuizMobile/Rating_star1.svg";
// import Rating2 from "../../Assets/QuizMobile/Rating2.svg";
// import Rating3 from "../../Assets/QuizMobile/Rating3.svg";
// import Rating4 from "../../Assets/QuizMobile/Rating4.svg";
// import Rating5 from "../../Assets/QuizMobile/Rating5.svg";

export default function RankingPage() {
  const [rank, setRank] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [getLength, setGetLength] = useState(0);
  const [rating, setRating] = useState(0);

  const fetchRank = async () => {
    try {
      const res = await _nonAuthHttp.get("/quiz/get-participants");
      setRank(res.data);
      setModalVisible(true);
      setGetLength(res.data.length);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRank();
  }, []);

  const downloadHandler = () => {
    if (rank.length === 0) {
      return alert("No data to download");
    }

    // Define CSV headers
    const headers = ["Rank", "Name", "Score"];

    // Generate CSV content
    const csvRows = [
      headers.join(","), // Add the header row
      ...rank.map((entry) => `${entry.id},${entry.name},${entry.total_score}`), // Add data rows
    ];

    // Create a blob from CSV string
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });

    // Create a temporary download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "ranking_table.csv";

    // Trigger download by programmatically clicking the link
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  };

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: function (chart) {
      const width = chart.width;
      const height = chart.height;
      const ctx = chart.ctx;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "middle";
      const text = getLength; // Your dynamic text here
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };
  const data = {
    datasets: [
      {
        data: [1000, 800, 200],
        backgroundColor: ["#1E6641", "#FF3030", "#DFDFDF"],
        hoverBackgroundColor: ["#1E6641", "#558B70", "#CEDDD5"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend, as we are customizing it
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((sum, value) => sum + value, 0);
            const currentValue = dataset.data[tooltipItem.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${currentValue} (${percentage}%)`;
          },
        },
      },
    },
    cutout: "60%", // Controls the size of the hole in the middle
  };

  const closeHandler = () => {
    setModalVisible(false);
  };

  const handleRatingClick = (index) => {
    console.log(rating);
    setRating(index + 1); // Set rating based on clicked star index (0-based)
  };

  const renderRatingStars = () => {
    return Array.from({ length: 5 }, (_, index) => {
      const isFilled = index < rating; // Check if this star should be filled or hollow
      const starImage = isFilled ? FullStarRating : HollowStarRating; // Use full star or hollow star

      return (
        <img
          key={index}
          src={starImage}
          alt={`Rating ${index + 1}`}
          onClick={() => handleRatingClick(index)}
          style={{ width: "45px", height: "50px" }}
        />
      );
    });
  };

  return (
    <div className="ranking_page">
      <div className="logo-img">
        <img src={logoImg} alt="error" />
        <img
          src={material_symbols_download}
          alt="error"
          onClick={downloadHandler}
        />
      </div>
      <div className="score_bar">
        <p className="ranki">Welding Quiz 1 Score</p>
        <div>
          <Doughnut
            data={data}
            options={options}
            plugins={[centerTextPlugin]}
          />
        </div>
      </div>
      <div className="outsider">
        <div className="resultBar">
          <div className="rs_div">
            <p className="dot1"></p>
            Correct
          </div>
          <div className="rs_div">
            <p className="dot2"></p>
            Incorrect
          </div>
          <div className="rs_div">
            <p className="dot3"></p>
            Unattenpted
          </div>
        </div>
      </div>
      <div className="table_container">
        <p className="sub_rank">Ranking</p>
        <div className="ranking_table">
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {rank.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.id}</td>
                  <td>{entry.name}</td>
                  <td>{entry.total_score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        className="rating_modal"
        open={modalVisible}
        // onCancel={() => setModalVisible(false)}
        closable={false}
      >
        <div className="inside-modal">
          <img src={checkImg} alt="error" />
          <p className="rat_experience">How do you rate your Quiz experience</p>
          <div> {renderRatingStars()} </div>
          <p className="skip" onClick={closeHandler}>
            Skip
          </p>
        </div>
      </Modal>
      {/* <div className="btn_quiz">
        <button className="quizBtn">Try Another Quiz</button>
      </div> */}
    </div>
  );
}
