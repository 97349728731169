import React, { useState, useEffect, useCallback, useMemo } from "react";
import SalesTable from "./SalesTable";
import SalesRange from "./SalesRange";
import RevenueChart from "./RevenueGraph";
import "./Dashboard.scss";
import { rem } from "../../../Components/Rem_func";
import DeclineReason from "./DeclineReason";
import QuotedResponse from "./QuotedResponse";
import BranchBarChart from "./BranchBarChart";
import SalesBieChart from "./SalesBieChart";
import SalesHeader from "./SalesHeader";
import _http from "../../../Utils/Api/_http";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";
import CrossIcon from "../../../Components/Svg/CrossIcon";

const DashBoard = () => {
  const [employeesArray, setEmployeesArray] = useState({
    name: [],
    count: [],
    order_count: [],
    quote_count: [],
    orderValueSums: [],
  });
  const [salesrange, setSalesRange] = useState({
    quote: [],
    awarded: [],
    enquiries: [],
    conversion: [],
  });
  const [userList, setUserList] = useState([]);
  const [users, setusers] = useState([]);
  const [filteredData, setFilteredData] = useState({
    managers: [],
    teamleads: [],
    employees: [],
  });
  const [role, setRole] = useState("");
  const access = useSelector(selectUserData);

  const [filters, setFilters] = useState({
    location: "",
    quick_filter: "Last week",
    year: [],
    quarter: [],
    start_date: "",
    end_date: "",
    currency: "",
  });
  const [filterOn, setFilterOn] = useState(false);
  const fetchData1 = useCallback(async () => {
    try {
      // // setLoading(true);
      // const params = {
      //   ...filters,
      //   // year: filters.year.length ? filters.year.join(",") : "", // Convert year array to comma-separated string
      //   quarter: filters.quarter.length ? filters.quarter.join(",") : "", // Convert quarter array to comma-separated string
      // };
      const response = await _http.get("/api/users_db");

      if (Array.isArray(response.data) && response.data.every(Array.isArray)) {
        const flattenedData = response.data.flat();

        setusers(flattenedData.flat());
        // dispatch(setdatacount(flattenedData?.flat()?.length));
      } else {
        // setUsers(response.data);
        // dispatch(setdatacount(response.data.length));
      }
    } catch (error) {
      // setToast({ error: true });
      // setError("Error fetching data:", error?.message);
      console.log(error);
    }
    // setLoading(false);
  }, []);
  const isWithinQuarter = (date, quarter) => {
    const month = date.getMonth() + 1; // JavaScript months are 0-based, so add 1

    switch (quarter) {
      case "Q1":
        return month >= 1 && month <= 3; // January to March
      case "Q2":
        return month >= 4 && month <= 6; // April to June
      case "Q3":
        return month >= 7 && month <= 9; // July to September
      case "Q4":
        return month >= 10 && month <= 12; // October to December
      default:
        return false;
    }
  };

  // Function to compute date ranges based on quick filters
  const computeDateRange = (quickFilter) => {
    const now = new Date();
    let startDate;

    switch (quickFilter) {
      case "Yesterday":
        startDate = new Date(now.setDate(now.getDate() - 1));
        break;
      case "Last 3 days":
        startDate = new Date(now.setDate(now.getDate() - 3));
        break;
      case "Last week":
        startDate = new Date(now.setDate(now.getDate() - 7));
        break;
      case "Last 2 weeks":
        startDate = new Date(now.setDate(now.getDate() - 14));
        break;
      case "Last 3 weeks":
        startDate = new Date(now.setDate(now.getDate() - 21));
        break;
      case "Last month":
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case "Last 3 months":
        startDate = new Date(now.setMonth(now.getMonth() - 3));
        break;
      case "Last 6 months":
        startDate = new Date(now.setMonth(now.getMonth() - 6));
        break;
      case "Last 9 months":
        startDate = new Date(now.setMonth(now.getMonth() - 9));
        break;
      case "Last year":
        startDate = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      default:
        startDate = null;
    }

    return startDate;
  };

  const filteredUsers = useMemo(() => {
    let filteredManagers = [];
    let filteredTeamLeads = [];
    let filteredEmployees = [];
    const userRole = role;

    // Role-based filtering for admin, manager, and team lead
    if (userRole === "admin") {
      filteredManagers = filteredData.managers.length
        ? userList.filter(
            (user) =>
              filteredData.managers.includes(user.user) &&
              user.role_name === "Manager"
          )
        : userList.filter((user) => user.role_name === "Manager");

      filteredTeamLeads = filteredData.teamleads.length
        ? userList.filter(
            (user) =>
              filteredData.teamleads.includes(user.user) &&
              user.role_name === "Teamlead" &&
              filteredManagers.some(
                (manager) => user.reporting_to === manager.user
              )
          )
        : userList.filter(
            (user) =>
              user.role_name === "Teamlead" &&
              filteredManagers.some(
                (manager) => user.reporting_to === manager.user
              )
          );

      filteredEmployees = filteredData.employees.length
        ? userList.filter(
            (user) =>
              filteredData.employees.includes(user.user) &&
              user.role_name === "employee" &&
              filteredTeamLeads.some((tl) => user.reporting_to === tl.user)
          )
        : userList.filter(
            (user) =>
              user.role_name === "employee" &&
              filteredTeamLeads.some((tl) => user.reporting_to === tl.user)
          );
    } else if (userRole === "Manager") {
      filteredTeamLeads = filteredData.teamleads.length
        ? userList.filter(
            (user) =>
              filteredData.teamleads.includes(user.user) &&
              user.role_name === "Teamlead"
          )
        : userList.filter((user) => user.role_name === "Teamlead");

      filteredEmployees = filteredData.employees.length
        ? userList.filter(
            (user) =>
              filteredData.employees.includes(user.user) &&
              user.role_name === "employee" &&
              filteredTeamLeads.some((tl) => user.reporting_to === tl.user)
          )
        : userList.filter(
            (user) =>
              user.role_name === "employee" &&
              filteredTeamLeads.some((tl) => user.reporting_to === tl.user)
          );
    } else if (userRole === "Teamlead") {
      filteredEmployees = filteredData.employees.length
        ? userList.filter(
            (user) =>
              filteredData.employees.includes(user.user) &&
              user.role_name === "employee"
          )
        : userList.filter((user) => user.role_name === "employee");
    } else {
      filteredEmployees = users;
    }

    const filteredEmployeeNames = filteredEmployees.map((emp) => emp.user);

    const filteredSalesData = (users || []).filter(
      (item) =>
        filteredEmployeeNames.includes(item.sales_person_name || "") || users
    );

    console.log(users);

    return (
      (filteredSalesData || []).filter((item) => {
        const emailDate = new Date(item?.client_email_time?.split(" ")[0]);
        const year = emailDate.getFullYear();

        const isYearIncluded =
          filters.year.length > 0 ? filters.year.includes(year) : true;

        const isAfterStartDate = filters.start_date
          ? emailDate >= new Date(filters.start_date)
          : true;

        const isBeforeLastDate = filters.end_date
          ? emailDate <= new Date(filters.end_date)
          : true;

        const isInQuarter =
          filters.quarter.length > 0
            ? filters.quarter.some((q) => isWithinQuarter(emailDate, q))
            : true;

        const branch = filters.location
          ? filters.location.includes(item?.location)
          : true;

        // Currency, branch, and quick filter checks
        const isCurrencyMatched = filters.currency
          ? filters.currency.includes(item?.currency)
          : true;
        // Quick filter handling
        let quickFilterStartDate = null;
        if (filters.quick_filter) {
          quickFilterStartDate = computeDateRange(filters.quick_filter);
        }
        const isWithinQuickFilter = quickFilterStartDate
          ? emailDate >= quickFilterStartDate
          : true;

        return (
          (filters.year.length === 0 || isYearIncluded) &&
          isAfterStartDate &&
          isBeforeLastDate &&
          isInQuarter &&
          branch &&
          isCurrencyMatched &&
          isWithinQuickFilter
        );
      }) || []
    );
  }, [filters, users, filteredData, userList, role]);

  // Dependencies for useMemo

  const fetchData2 = useCallback(async () => {
    // setLoading(true);
    if (access.role_name === "employee") {
      return;
    }
    try {
      const response = await _http.get("/api/get_users");
      const userLists = response.data.Output.record.flat();
      setUserList(userLists.flat());
      setRole(response?.data?.Output?.Role);
    } catch (error) {
      // setToast({ error: true });
      // setError("Error fetching data:", error.message);
    }
    // setLoading(false);
  }, [access]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);
  useEffect(() => {
    fetchData2();
  }, [fetchData2]);
  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Convert to billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Convert to millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Convert to thousands
    } else {
      return num?.toString(); // If less than a thousand, return as is
    }
  };
  //revenue graph

  //sales range code
  const calculateOrderValueSumByCurrency1 = (data) => {
    const currencySums = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };
    const quoteSums = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };
    const enquiries = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };
    const conversion = {
      ackCount: 0,
      orderCount: 0,
      quoteCount: 0,
    };

    // Filter data for the specific employee and sum up the order_value by currency
    data.forEach((item) => {
      // Ensure that order_value and currency_value are numbers
      const orderValue =
        parseFloat(item.order_value?.replace(/[^0-9.-]+/g, "")) || 0;
      const currencyValue =
        parseFloat(item.currency_value?.replace(/[^0-9.-]+/g, "")) || 0;

      // For USD
      if (item.currency === "USD") {
        currencySums.USD += orderValue;
        quoteSums.USD += currencyValue;
        enquiries.USD += 1; // Count the number of USD enquiries
      }

      // For AED
      else if (item.currency === "AED") {
        currencySums.AED += orderValue;
        quoteSums.AED += currencyValue;
        enquiries.AED += 1; // Count the number of AED enquiries
      }

      // For OMR
      else if (item.currency === "OMR") {
        currencySums.OMR += orderValue;
        quoteSums.OMR += currencyValue;
        enquiries.OMR += 1; // Count the number of OMR enquiries
      }
    });

    // Count the number of orders and quotes across all data
    conversion.ackCount = data.length; // Total number of items (enquiries)
    conversion.orderCount = data.filter(
      (item) => item.reminder_status === "order_placed"
    ).length; // Count orders placed
    conversion.quoteCount = data.filter(
      (item) => item.reminder_status === "success"
    ).length; // Count quotes marked as success

    // Set the result
    setSalesRange({
      awarded: currencySums,
      quote: quoteSums,
      enquiries: enquiries,
      conversion: conversion,
    });
  };

  //sales table code

  useEffect(() => {
    calculateOrderValueSumByCurrency1(filteredUsers);
    let employeeNames = "";
    if (userList.length > 0) {
      employeeNames = userList
        .filter((user) => user?.role_name.includes("employee"))
        .map((user) => user.user);
    } else {
      employeeNames = [access?.name];
    }
    console.log(employeeNames);

    const employeeCounts = employeeNames.map((name) =>
      countOccurrences(name, filteredUsers, "sales_person_name")
    );

    const orderCount = employeeNames.map((name) =>
      ordercount(name, filteredUsers, "order_placed")
    );
    const quoteCount = employeeNames.map((name) =>
      ordercount(name, filteredUsers, "success")
    );
    const orderValueSums = employeeNames.map((name) =>
      calculateOrderValueSumByCurrency(name, filteredUsers)
    );

    setEmployeesArray({
      name: employeeNames,
      count: employeeCounts,
      order_count: orderCount,
      quote_count: quoteCount,
      orderValueSums: orderValueSums,
    });
  }, [userList, filteredUsers, access]);

  const countOccurrences = (name, data, item) => {
    return data.filter((items) => items[item] === name).length;
  };
  const ordercount = (name, data, statusKey) => {
    return data.filter(
      (item) =>
        item.sales_person_name === name && item.reminder_status === statusKey
    ).length;
  };
  const calculateOrderValueSumByCurrency = (name, data) => {
    // Initialize sums for each currency
    const currencySums = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };

    // Filter data for the specific employee and sum up the order_value by currency
    data.forEach((item) => {
      if (item.sales_person_name === name) {
        if (item.currency === "USD") {
          currencySums.USD +=
            parseFloat(item?.order_value?.replace(/[^0-9.-]+/g, "")) || 0;
        } else if (item.currency === "AED") {
          currencySums.AED +=
            parseFloat(item?.order_value?.replace(/[^0-9.-]+/g, "")) || 0;
        } else if (item.currency === "OMR") {
          currencySums.OMR +=
            parseFloat(item?.order_value?.replace(/[^0-9.-]+/g, "")) || 0;
        }
      }
    });

    return currencySums; // Return the sums for each currency
  };

  const checkIfFiltersAreApplied = (filters) => {
    return Object?.values(filters)?.some((value) => value.length > 0);
  };

  // Update filterOn whenever filteredData changes
  useEffect(() => {
    setFilterOn(checkIfFiltersAreApplied(filteredData));
  }, [filteredData]);

  const removeThisFilter = (key, index = null) => {
    setFilteredData((prev) => {
      const updatedData = { ...prev };

      if (Array.isArray(prev[key])) {
        if (index !== null) {
          // Handle array case when an index is provided (removing specific filter)
          const updatedArray = prev[key].filter((_, i) => i !== index); // Remove specific item by index
          updatedData[key] = updatedArray;

          if (key === "managers") {
            // If a manager is removed, find the associated team leads and employees
            const removedManager = prev[key][index]; // The manager that is being removed

            // Find the specific manager from userList
            const selectedManager = userList.find(
              (manager) => manager.manager === removedManager
            );

            if (selectedManager) {
              // Get team leads associated with the removed manager
              const associatedTeamLeads = selectedManager.team_leads.map(
                (tl) => tl.team_lead
              );
              // Remove only those team leads that are reporting to the removed manager
              updatedData.teamleads = updatedData.teamleads.filter(
                (tl) => !associatedTeamLeads.includes(tl)
              );

              // Get employees associated with the removed team leads
              const associatedEmployees = selectedManager.team_leads.flatMap(
                (tl) => tl.employees.map((emp) => emp.employee_name)
              );
              // Remove those employees from the filtered data
              updatedData.employees = updatedData.employees.filter(
                (emp) => !associatedEmployees.includes(emp)
              );
            }
          }

          if (key === "teamlead") {
            // If a team lead is removed, find the associated employees
            const removedTeamLead = prev[key][index]; // The team lead that is being removed

            const selectedTeamLead = userList
              .flatMap((manager) => manager.team_leads)
              .find((tl) => tl.teamlead === removedTeamLead);

            if (selectedTeamLead) {
              // Get employees associated with the removed team lead
              const associatedEmployees = selectedTeamLead.employees.map(
                (emp) => emp.employee_name
              );
              // Remove those employees from the filtered data
              updatedData.employees = updatedData.employees.filter(
                (emp) => !associatedEmployees.includes(emp)
              );
            }
          }
        } else {
          // Handle case when no index is provided (clear the entire array)
          updatedData[key] = [];

          // If clearing managers, clear team leads and employees
          if (key === "managers") {
            updatedData.teamleads = [];
            updatedData.employees = [];
          }

          // If clearing team leads, clear employees
          if (key === "teamleads") {
            updatedData.employees = [];
          }
        }
      } else {
        // Handle non-array values (single values)
        updatedData[key] = "";
      }

      return updatedData;
    });
  };

  return (
    <section className="df sales-dashboard flexColumn">
      <div className="container1 df flexColumn">
        <div className="w100">
          <SalesHeader
            userList={userList}
            filteredData={filteredData}
            setFilters={setFilters}
            filters={filters}
            filterOn={filterOn}
            setFilteredData={setFilteredData}
            DashBoard={true}
          />
        </div>
        <div className="df filter-section w100">
          {filterOn && filteredData && (
            <div className="filtered-data-item df">
              {Object.entries(filteredData).map(
                ([key, value]) =>
                  Array.isArray(value) &&
                  value.length > 0 && (
                    <div className="df filtered-array" key={key}>
                      {key
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (l) => l.toUpperCase())}
                      :
                      {value.map((item, index) => (
                        <span
                          key={`${key}-${index}`}
                          className="df filtered-option"
                        >
                          {" "}
                          <span
                            className="filtered-value"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.toLowerCase() || "N/A"}
                          </span>
                          <span
                            onClick={() => removeThisFilter(key, index)}
                            style={{ cursor: "pointer" }}
                          >
                            <CrossIcon />
                          </span>
                        </span>
                      ))}
                    </div>
                  )
              )}
            </div>
          )}
        </div>
        <div
          className="df align-start sales-contents w100"
          // style={{ paddingLeft: rem(11) }}
        >
          <div className="df flexColumn graph-content">
            <SalesRange salesrange={salesrange} formatNumber={formatNumber} />

            <div className="flexBox sales-graph" style={{ width: rem(635) }}>
              <BranchBarChart formatNumber={formatNumber} />
              <SalesBieChart users={filteredUsers} />
              <DeclineReason />
              <QuotedResponse />
            </div>
          </div>
          <div
            className="df flexColumn sales-graph-right"
            style={{ width: "40%" }}
          >
            <SalesTable
              employeesArray={employeesArray}
              formatNumber={formatNumber}
            />
            <RevenueChart formatNumber={formatNumber} users={filteredUsers} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashBoard;
