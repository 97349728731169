import React, { useEffect, useState } from "react";
import spiraLogo2 from "../../Assets/QuizMobile/spiraLogo2.svg";
import searchIcon from "../../Assets/QuizMobile/searchIcon.svg";
import { useNavigate } from "react-router-dom";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";

export default function StartQuiz() {
  const navigate = useNavigate();
  const [quizs, setQuizs] = useState([]);

  const participant_name = sessionStorage.getItem("participantsName");
  const poolName = sessionStorage.getItem("poolName");

  const fetchQuizData = async () => {
    try {
      const res = await _nonAuthHttp.get("/quiz/Get-question-pools");
      setQuizs(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchQuizData();
  }, []);

  const clickHandler = () => {
    navigate("/quiz-questions");
  };

  return (
    <div className="quizQuestion_container">
      <div className="upper_quiz">
        <img src={spiraLogo2} alt="error" />
        <p className="name_title">Hello, {participant_name}</p>
        <p className="knowledge">Lets test your knowledge</p>
        <div className="seach_div">
          <img src={searchIcon} alt="error" />
          <input type="text" placeholder="search" />
        </div>
      </div>
      <div className="lower_quiz">
        <div className="bar_div">
          <p className="bar"></p>
        </div>
        <div className="main_walla">
          <div className="selec_section">
            <p className="select_q">Select the quiz</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="75"
              height="1"
              viewBox="0 0 75 1"
              fill="none"
            >
              <path d="M0 0.5H75" stroke="url(#paint0_linear_233_31362)" />
              <defs>
                <linearGradient
                  id="paint0_linear_233_31362"
                  x1="-26.9"
                  y1="0.145"
                  x2="-26.7898"
                  y2="5.46625"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1E6641" />
                  <stop offset="1" stop-color="#57B383" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className="welding_quiz">
            {quizs.map((data, index) => (
              <div
                className="quiz_play"
                key={index}
                style={{
                  border: data.pool_name == poolName ? "1px solid #1E6641" : "",
                }}
              >
                <div className="left_quiz">
                  <p
                    className={
                      data.pool_name == poolName ? "quiz1" : "quiz1 blurStl"
                    }
                  >
                    {data.pool_name}
                  </p>
                  <div style={{ display: "flex", paddingTop: "5px" }}>
                    <div
                      className={data.pool_name == poolName ? "" : "blurStl"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M11.5133 13.4401H4.63327C4.15327 13.4401 3.7666 13.0534 3.7666 12.5734V3.96676C3.7666 3.48676 4.15327 3.1001 4.63327 3.1001H11.5133C11.9933 3.1001 12.3799 3.48676 12.3799 3.96676V12.5734C12.3799 13.0534 11.9933 13.4401 11.5133 13.4401Z"
                          stroke="#999999"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.04663 6.95337H8.76663"
                          stroke="#999999"
                          stroke-width="1.2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.04663 9.58667H10.1"
                          stroke="#999999"
                          stroke-width="1.2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p
                      className={
                        data.pool_name == poolName ? "ques" : "ques blurStl"
                      }
                    >
                      {data.question_count}
                    </p>
                  </div>
                  <div style={{ display: "flex", paddingTop: "5px" }}>
                    <div
                      className={data.pool_name == poolName ? "" : "blurStl"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.30669 14.2132C11.5689 14.2132 14.2134 11.5687 14.2134 8.30657C14.2134 5.04441 11.5689 2.3999 8.30669 2.3999C5.04453 2.3999 2.40002 5.04441 2.40002 8.30657C2.40002 11.5687 5.04453 14.2132 8.30669 14.2132Z"
                          stroke="#999999"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.30664 5.08008V8.30675L10.2933 9.25341"
                          stroke="#999999"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p
                      className={
                        data.pool_name == poolName ? "ques" : "ques blurStl"
                      }
                    >
                      {data.duration_in_minutes} min
                    </p>
                  </div>
                  <div style={{ display: "flex", paddingTop: "5px" }}>
                    <p
                      className={
                        data.pool_name == poolName
                          ? "total_ques"
                          : "total_ques blurStl"
                      }
                    >
                      Attempts:69247
                    </p>
                    <p
                      className={
                        data.pool_name == poolName
                          ? "total_ques"
                          : "total_ques blurStl"
                      }
                    >
                      Last Updated: Aug 25, 2025
                    </p>
                  </div>
                </div>
                <div
                  className={
                    data.pool_name == poolName ? "" : "right_quiz blurStl"
                  }
                >
                  <p>4.8</p>
                </div>
              </div>
            ))}

            {/* <p className="cont_quiz">Continue Quiz</p>
            <div className="quiz_result">
              <div className="left_quiz">
                <p className="quiz1">Welding Quiz 1</p>
                <div style={{ display: "flex", paddingTop: "5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M11.5133 13.4401H4.63327C4.15327 13.4401 3.7666 13.0534 3.7666 12.5734V3.96676C3.7666 3.48676 4.15327 3.1001 4.63327 3.1001H11.5133C11.9933 3.1001 12.3799 3.48676 12.3799 3.96676V12.5734C12.3799 13.0534 11.9933 13.4401 11.5133 13.4401Z"
                      stroke="#999999"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.04663 6.95337H8.76663"
                      stroke="#999999"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.04663 9.58667H10.1"
                      stroke="#999999"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="ques">8/10 Question</p>
                </div>
                <div style={{ display: "flex", paddingTop: "5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.30669 14.2132C11.5689 14.2132 14.2134 11.5687 14.2134 8.30657C14.2134 5.04441 11.5689 2.3999 8.30669 2.3999C5.04453 2.3999 2.40002 5.04441 2.40002 8.30657C2.40002 11.5687 5.04453 14.2132 8.30669 14.2132Z"
                      stroke="#999999"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.30664 5.08008V8.30675L10.2933 9.25341"
                      stroke="#999999"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="ques">30 min</p>
                </div>
                <div style={{ paddingTop: "15px" }}>
                  <button className="continue_btn">Continue Quiz</button>
                </div>
              </div>
              <div className="right_quiz">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M12.3974 15.8475H5.29492C4.46992 15.8475 3.79492 15.1725 3.79492 14.3475V4.20752H13.8974V14.3475C13.8974 15.1725 13.2224 15.8475 12.3974 15.8475Z"
                    stroke="#999999"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.95007 4.20752H15.7426"
                    stroke="#999999"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.92 4.20745V2.69995C10.92 2.28745 10.5825 1.94995 10.17 1.94995H7.51502C7.10252 1.94995 6.76501 2.28745 6.76501 2.69995V4.20745"
                    stroke="#999999"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.26001 6.62988V12.5999"
                    stroke="#999999"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.425 6.62988V12.5999"
                    stroke="#999999"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div> */}
            <div className="btn_quiz">
              <button className="quizBtn" onClick={clickHandler}>
                Start Quiz
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
