import { React, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import Delete from "../../Assets/Quiz/Delete.svg";
import { rem } from "../../Components/Rem_func";
import _http from "../../Utils/Api/_http";
const QuizUpload = () => {
  //Question Data
  const formDataFromStorage = JSON.parse(sessionStorage.getItem("formData"));
  //Quiz Uploaded Filename
  const QuizFileName = sessionStorage.getItem("QuizUpload");

  const [qrcode, setQrcode] = useState(false);

  const title = formDataFromStorage?.[0].title; // assuming the title is in the first item of the array
  const [PoolData, setPoolData] = useState({
    pool_name: title,
    duration_in_minutes: 30,
    schedule_time: "2024-12-4T14:11:00Z",
  });

  //formated Questions


  function handleDurationKeyDown(e) {
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
      e.preventDefault();
    }
  }

  function handleDurationChange(e) {
    const value = e.target.value;
    const pattern = /^[0-9]{1,2}$/; // allows 1 or 2 digits
    if (pattern.test(value)) {
      // input is valid, update the state
      setPoolData({ ...PoolData, duration_in_minutes: parseInt(value) });
    } else {
      // input is invalid, show an error message
      console.error("Invalid duration");
    }
  }

  useEffect(() => {
    const Create_question_Pool = async () => {
      try {
        const response = await _http.post("/quiz/question-pools", PoolData);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    Create_question_Pool();
  },[]);

  return (
    <div className="uploadcontainer">
      {/* <div className="uploadcreateimg">
        <img src={Create} alt="" />
      </div> */}
      <div className="upltiltle">
        <h1 contentEditable="true">
          {formDataFromStorage[0].title}{" "}
          <span>C: Laura\My Documents\Welding Quiz 1.pdf</span>
        </h1>

        <div className="uplbtns">
          <label htmlFor="duration">
            Duration:
            <input
              type="text"
              name="duration"
              id="duration"
              placeholder="30"
              maxLength={2}
              minLength={2}
              onKeyDown={handleDurationKeyDown}
              onChange={handleDurationChange}
              pattern="[0-9]{1,2}"
              required
            />
            mins
          </label>
          <label htmlFor="NoQuestions">
            No of Questions
            <input
              type="text"
              name="NoQuestions"
              id="NoQuestions"
              readOnly={true}
              placeholder=""
              value={formDataFromStorage?.length}
            />
          </label>
          <img src={Delete} alt="" />
        </div>
      </div>
      <div className="quizuplbtton">
        <Button
          btnname={!qrcode ? "Generate QR Code" : "Submit"}
          height={50}
          width={200}
        />
      </div>
    </div>
  );
};
export default QuizUpload;
