// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #fcb02a;
  --primary-color-rgb: 252, 176, 42;
  --secondary-color: #757575;
  --secondary-color-rgb: 112, 112, 112;
  --body_font_color: #262626;
  --machine-alert: var(--primary-color);
  --machine-warning: #ff2600;
  --machine-success: #27c24c;
  --default-border: #dfdfdf;
  --default-border-rgb: 208, 208, 208;
  --body-bg: #f2f3f7;
  --static-white: #fff;
}

.edit-popup .ant-modal-footer {
  display: none;
}
.edit-popup .ant-modal-content {
  height: inherit;
}
.edit-popup .ant-modal-body {
  max-height: 80vh;
  overflow: auto;
}
.edit-popup .user-popup .sales-popup {
  flex-direction: row;
  justify-content: space-between;
}

.edit-popup .anticon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.api-btn {
  gap: 2rem;
}

.ant-modal .ant-modal-content {
  padding: 3rem;
  border-radius: 0.5rem;
}
.ant-modal .ant-modal-close {
  top: 1.0625rem;
  width: 1.375rem;
  height: 1.375rem;
  inset-inline-end: 1.0625rem;
}`, "",{"version":3,"sources":["webpack://./src/Sass/Variable.scss","webpack://./src/Pages/SalesTracker/STcomponents/STeditPopup/STeditPopup.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,iCAAA;EACA,0BAAA;EACA,oCAAA;EACA,0BAAA;EACA,qCAAA;EACA,0BAAA;EACA,0BAAA;EACA,yBAAA;EACA,mCAAA;EACA,kBAAA;EACA,oBAAA;ACCF;;AAXE;EACE,aAAA;AAcJ;AAZE;EACE,eAAA;AAcJ;AAZE;EACE,gBAAA;EACA,cAAA;AAcJ;AAXI;EACE,mBAAA;EACA,8BAAA;AAaN;;AARA;EACE,aAAA;EACA,cAAA;AAWF;;AARA;EACE,SAAA;AAWF;;AAPE;EACE,aAAA;EACA,qBAAA;AAUJ;AARE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,2BAAA;AAUJ","sourcesContent":[":root {\n  --primary-color: #fcb02a;\n  --primary-color-rgb: 252, 176, 42;\n  --secondary-color: #757575;\n  --secondary-color-rgb: 112, 112, 112;\n  --body_font_color: #262626;\n  --machine-alert: var(--primary-color);\n  --machine-warning: #ff2600;\n  --machine-success: #27c24c;\n  --default-border: #dfdfdf;\n  --default-border-rgb: 208, 208, 208;\n  --body-bg: #f2f3f7;\n  --static-white: #fff;\n}\n\n// REM Calculations\n$baseFontSize: 16px;\n@function rem($pixels, $context: $baseFontSize) {\n  @if (unitless($pixels)) {\n    $pixels: $pixels * 1px;\n  }\n  @if (unitless($context)) {\n    $context: $context * 1px;\n  }\n  @return $pixels / $context * 1rem;\n}\n\n$box-border: rem(1) solid #e0e7ed;\n","@import \"../../../../Sass/Variable.scss\";\n.edit-popup {\n  .ant-modal-footer {\n    display: none;\n  }\n  .ant-modal-content {\n    height: inherit;\n  }\n  .ant-modal-body {\n    max-height: 80vh;\n    overflow: auto;\n  }\n  .user-popup {\n    .sales-popup {\n      flex-direction: row;\n      justify-content: space-between;\n    }\n  }\n}\n\n.edit-popup .anticon svg {\n  width: rem(24);\n  height: rem(24);\n}\n\n.api-btn {\n  gap: rem(32);\n}\n\n.ant-modal {\n  .ant-modal-content {\n    padding: rem(48);\n    border-radius: rem(8);\n  }\n  .ant-modal-close {\n    top: rem(17);\n    width: rem(22);\n    height: rem(22);\n    inset-inline-end: rem(17);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
