// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #fcb02a;
  --primary-color-rgb: 252, 176, 42;
  --secondary-color: #757575;
  --secondary-color-rgb: 112, 112, 112;
  --body_font_color: #262626;
  --machine-alert: var(--primary-color);
  --machine-warning: #ff2600;
  --machine-success: #27c24c;
  --default-border: #dfdfdf;
  --default-border-rgb: 208, 208, 208;
  --body-bg: #f2f3f7;
  --static-white: #fff;
}

.edit-popup {
  width: 100%;
}

.user-popup {
  display: flex;
  flex-wrap: wrap;
  max-width: 50.5rem;
  height: inherit;
}
.user-popup .popup {
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  width: auto;
}
.user-popup .popup-line {
  border: 0.0625rem solid #e8e8e8;
  margin: 0 2.75rem;
}
.user-popup .permission-box {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.user-popup .permission-box .permission {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
}
.user-popup .permission-box label {
  font-size: 0.875rem;
}`, "",{"version":3,"sources":["webpack://./src/Sass/Variable.scss","webpack://./src/Pages/User/UserPopup/UserPopup.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,iCAAA;EACA,0BAAA;EACA,oCAAA;EACA,0BAAA;EACA,qCAAA;EACA,0BAAA;EACA,0BAAA;EACA,yBAAA;EACA,mCAAA;EACA,kBAAA;EACA,oBAAA;ACCF;;AAXA;EACE,WAAA;AAcF;;AAVA;EACE,aAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;AAaF;AAXE;EACE,sBAAA;EACA,WAAA;EACA,uBAAA;EACA,WAAA;AAaJ;AAXE;EACE,+BAAA;EACA,iBAAA;AAaJ;AAXE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAaJ;AAZI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;AAcN;AAZI;EACE,mBAAA;AAcN","sourcesContent":[":root {\n  --primary-color: #fcb02a;\n  --primary-color-rgb: 252, 176, 42;\n  --secondary-color: #757575;\n  --secondary-color-rgb: 112, 112, 112;\n  --body_font_color: #262626;\n  --machine-alert: var(--primary-color);\n  --machine-warning: #ff2600;\n  --machine-success: #27c24c;\n  --default-border: #dfdfdf;\n  --default-border-rgb: 208, 208, 208;\n  --body-bg: #f2f3f7;\n  --static-white: #fff;\n}\n\n// REM Calculations\n$baseFontSize: 16px;\n@function rem($pixels, $context: $baseFontSize) {\n  @if (unitless($pixels)) {\n    $pixels: $pixels * 1px;\n  }\n  @if (unitless($context)) {\n    $context: $context * 1px;\n  }\n  @return $pixels / $context * 1rem;\n}\n\n$box-border: rem(1) solid #e0e7ed;\n","@import \"../../../Sass/Variable.scss\";\n\n.edit-popup {\n  width: 100%;\n  // overflow: auto;\n}\n\n.user-popup {\n  display: flex;\n  flex-wrap: wrap;\n  max-width: rem(808);\n  height: inherit;\n\n  .popup {\n    flex-direction: column;\n    gap: rem(24);\n    align-items: flex-start;\n    width: auto;\n  }\n  .popup-line {\n    border: rem(1) solid #e8e8e8;\n    margin: 0 rem(44);\n  }\n  .permission-box {\n    display: flex;\n    flex-direction: column;\n    gap: rem(10);\n    .permission {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      gap: rem(10);\n    }\n    label {\n      font-size: rem(14);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
