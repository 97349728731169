import React, { useState } from "react";
import { Modal, Table } from "antd";
import "./Quotegenerator.scss";
import Button from "../../Components/Button/Button";
import _http from "../../Utils/Api/_http";
// import QuotePopup from "./QuotePopup";
import SelectSearch from "../../Components/SelectFields/SelectField";
import InputField from "../../Components/InputField/InputField";
import { rem } from "../../Components/Rem_func";
import SnackBar from "../../Components/SnackBar";

const QuoteGenerator = () => {
  const [furtherError, setFurtherErrors] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [add, setAdd] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [formdata, setFormdata] = useState({
    item: "",
    winding_material: "",
    filler_material: "",
    inner_ring_material: "",
    outer_ring_material: "",
    material_size: "",
    rating: "",
  });
  const [tableData, setTableData] = useState({
    quantity: 10,
    currency: "AED",
    local_tax: 10,
    margin: 10,
  });
  const [quote, setQuote] = useState();
  const [data_1, setCombinedTableData] = useState([]);
  const [data_2, setFurtherDetail] = useState({
    c_name: "",
    att: "",
    add: "",
    date: "",
    dely: "",
    ship: "",
  });
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");

  // useState end

  // function start from here

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();

  // Function to handle toast closing
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  // add api call
  const handleQuote = async () => {
    const requiredFields = [
      "item",
      "winding_material",
      "filler_material",
      "inner_ring_material",
      "outer_ring_material",
      "material_size",
      "rating",
      "quantity",
      "currency",
      "local_tax",
      "margin",
    ];
    const emptyFields = [];

    setFurtherErrors((prevData) => {
      const newData = { ...prevData };
      const err_data = { ...formdata, ...tableData };
      requiredFields.forEach((field) => {
        if (!err_data[field] || err_data[field].toString().trim() === "") {
          newData[`${field}_error`] = true;
          emptyFields.push(field);
        } else {
          newData[`${field}_error`] = false;
        }
      });
      return newData;
    });

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });

    // If there are empty fields, exit the function
    if (emptyFields.length > 0) {
      setToast({ error: true });
      setError("please fill all the input fields");
      return;
    }
    setAdd(true);
    try {
      const response = await _http.post(
        "/api/price_list",
        formdata, // Send formdata directly, not wrapped in an object
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 404) {
        setError("Please change the material combination");
        setToast({ error: true });
        return;
      }
      const fetchedQuote = response?.data[0]?.price; // Retrieve the actual quote value
      setQuote(fetchedQuote);
      const newEntry = {
        slNo: data_1.length + 1,
        desp: Object.values(formdata).join(", "),
        qty: tableData.quantity,
        rate: `${fetchedQuote} ${tableData.currency}`,
        cost: `${tableData.quantity * fetchedQuote} ${tableData.currency}`,
        margin: `${tableData.margin}%`,
        quote: `${(
          tableData.quantity *
          fetchedQuote *
          (1 + tableData.margin / 100)
        ).toFixed(2)} ${tableData.currency}`,
      };

      // Append new data to combinedTableData
      setCombinedTableData((prevData) => [...prevData, newEntry]);
      setFormdata({
        item: "",
        winding_material: "",
        filler_material: "",
        inner_ring_material: "",
        outer_ring_material: "",
        material_size: "",
        rating: "",
      });
      setTableData({ ...tableData, quantity: "", margin: "" });
    } catch (error) {
      if (error.request.status === 404) {
        setError("Please change the material combination");
        setToast({ error: true });
      }
      // console.error(error);
      // setToast({ error: true });
      // setError("Internal server error");
    }
    setAdd(false);
  };

  // const handleChangedata = (value, field) => {
  //   setTableData((prevTableData) => ({
  //     ...prevTableData,
  //     [field]: value,
  //   }));
  // };

  const handleReset = () => {
    setFormdata({
      item: "",
      winding_material: "",
      filler_material: "",
      inner_ring_material: "",
      outer_ring_material: "",
      material_size: "",
      rating: "",
    });
    setTableData({ quantity: "", margin: "", currency: "", tax: "" });
    setQuote("");
    setCombinedTableData([]);
  };

  const handleGenerate = async () => {
    const requiredFields = ["c_name", "att", "add", "date", "dely", "ship"];

    const emptyFields = [];
    if (!data_1[0]) {
      setError("please add product data");
      setToast({ error: true });

      return;
    }
    setFurtherErrors((prevData) => {
      const newData = { ...prevData };
      const err_data = { ...formdata, ...data_2, ...tableData };

      requiredFields.forEach((field) => {
        if (!err_data[field] || err_data[field].toString().trim() === "") {
          newData[`${field}_error`] = true;
          emptyFields.push(field);
        } else {
          newData[`${field}_error`] = false;
        }
      });
      return newData;
    });

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });

    // If there are empty fields, exit the function
    if (emptyFields.length > 0) {
      setError("please fill all the fields");
      setToast({ error: true });
      return;
    }

    setGenerate(true);
    try {
      const response = await _http.post(
        "/api/quote_file",
        {
          data_1,
          data_2,
          Tcost: data_1.reduce(
            (total, item) => total + parseFloat(item.quote.split(" ")[0]),
            0
          ),
          tax: Tcost * (tableData.local_tax / 100),
          f_tax: tax + Tcost,
        }, // Send formdata directly, not wrapped in an object
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      setModalContent(
        <embed
          width={"100%"}
          height={"100%"}
          src={URL.createObjectURL(blob)}
          type="application/pdf"
        />
      );
      setModalVisible(true);
      // Append new data to combinedTableData
      setFurtherDetail({
        c_name: "",
        att: "",
        add: "",
        date: "",
        dely: "",
        ship: "",
      });
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
    setGenerate(false);
  };

  const handleSelectChange = (name, value, setFormdata, formdata) => {
    setFormdata({ ...formdata, [name]: value });
  };

  const columns = [
    {
      title: "Sl.No",
      dataIndex: "slNo",
      key: "slNo",
      className: "",
    },
    {
      title: "Product Description",
      dataIndex: "desp",
      key: "desp",
      className: "",
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      className: "",
    },
    {
      title: "Unit Rate",
      dataIndex: "rate",
      key: "rate",
      className: "",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      className: "",
    },
    {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
      className: "",
    },
    {
      title: "Quote",
      dataIndex: "quote",
      key: "quote",
      className: "",
    },
  ];

  const options1 = ["SWG"];
  const options2 = ["SS 316", "SS 304"];
  const options3 = ["GR"];
  const options4 = ["SS 316", "SS 304"];

  const options5 = ["CS", "316", "304"];

  const options6 = [
    "0.5",
    "0.75",
    "1",
    "1.25",
    "1.5",
    "2",
    "2.5",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "14",
    "16",
    "18",
    "20",
    "24",
  ];
  const options7 = ["150", "300", "600", "900", "1500", "2500", "2500"];

  const currency = ["USD", "AED"];

  // Calculate totals
  const Tcost = data_1.reduce(
    (total, item) => total + parseFloat(item.quote.split(" ")[0]),
    0
  );
  const tax = Tcost * (tableData.local_tax / 100);
  const ftax = Tcost + tax;

  const summaryData = [
    {
      key: "Tcost",
      margin: "Total Cost",
      quote: `${Tcost.toFixed(2)} ${tableData.currency}`,
    },
    {
      key: "tax",
      margin: "Tax Value",
      quote: `${tax.toFixed(2)} ${tableData.currency}`,
    },
    {
      key: "ftax",
      margin: "Total Cost Including Tax",
      quote: `${ftax.toFixed(2)} ${tableData.currency}`,
    },
  ];

  return (
    <section className="quality">
      <div className="container1">
        <div className="title" style={{ paddingTop: rem(50) }}>
          Quote Generator
        </div>

        <div className="main_container">
          <div className="flexBox Quote-input">
            <div style={{ width: rem(300) }}>
              <SelectSearch
                label="Spira Products"
                name="item"
                Placeholder="Choose option"
                options={options1}
                value={formdata.item}
                onChange={(value) =>
                  handleSelectChange("item", value, setFormdata, formdata)
                }
                hasError={furtherError.item_error}
                readOnly={add}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <SelectSearch
                label="Winding Material"
                name="winding_material"
                Placeholder="Choose option"
                options={options2}
                value={formdata.winding_material}
                onChange={(value) =>
                  handleSelectChange(
                    "winding_material",
                    value,
                    setFormdata,
                    formdata
                  )
                }
                hasError={furtherError.winding_material_error}
                readOnly={add}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <SelectSearch
                label="Filler Material"
                name="filler_material"
                Placeholder="Choose option"
                options={options3}
                value={formdata.filler_material}
                onChange={(value) =>
                  handleSelectChange(
                    "filler_material",
                    value,
                    setFormdata,
                    formdata
                  )
                }
                hasError={furtherError.filler_material_error}
                readOnly={add}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <SelectSearch
                label="Inner Ring Material"
                name="inner_ring_material"
                Placeholder="Choose option"
                options={options4}
                value={formdata.inner_ring_material}
                onChange={(value) =>
                  handleSelectChange(
                    "inner_ring_material",
                    value,
                    setFormdata,
                    formdata
                  )
                }
                hasError={furtherError.inner_ring_material_error}
                readOnly={add}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <SelectSearch
                label="Outer Ring Material"
                name="outer_ring_material"
                Placeholder="Choose option"
                options={options5}
                value={formdata.outer_ring_material}
                onChange={(value) =>
                  handleSelectChange(
                    "outer_ring_material",
                    value,
                    setFormdata,
                    formdata
                  )
                }
                hasError={furtherError.outer_ring_material_error}
                readOnly={add}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <SelectSearch
                label="Size"
                name="material_size"
                Placeholder="Choose option"
                options={options6}
                value={formdata.material_size}
                onChange={(value) =>
                  handleSelectChange(
                    "material_size",
                    value,
                    setFormdata,
                    formdata
                  )
                }
                hasError={furtherError.material_size_error}
                readOnly={add}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <SelectSearch
                label="Rating"
                name="rating"
                Placeholder="Choose option"
                options={options7}
                value={formdata.rating}
                onChange={(value) =>
                  handleSelectChange("rating", value, setFormdata, formdata)
                }
                hasError={furtherError.rating_error}
                readOnly={add}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                label={"quantity"}
                labelName={"Quantity"}
                // type={"number"}
                placeholder={""}
                fieldData={tableData.quantity}
                setFormData={setTableData}
                maxLength={10}
                hasError={furtherError.quantity_error}
                pattern={"numberOnly"}
                readOnly={add}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <SelectSearch
                label="Currency"
                name="currency"
                Placeholder="Choose option"
                options={currency}
                value={tableData.currency}
                onChange={(value) =>
                  handleSelectChange("currency", value, setTableData, tableData)
                }
                hasError={furtherError.currency_error}
                readOnly={add}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"local_tax"}
                labelName={"Local Tax ( In Percentage % )"}
                // type={"number"}
                placeholder={""}
                fieldData={tableData.local_tax}
                setFormData={setTableData}
                maxLength={5}
                hasError={furtherError.local_tax_error}
                pattern={"numberWithFloat"}
                readOnly={add}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"margin"}
                labelName={"Margin ( In Percentage % )"}
                // type={"number"}
                placeholder={""}
                fieldData={tableData.margin}
                setFormData={setTableData}
                maxLength={20}
                hasError={furtherError.margin_error}
                pattern={"numberWithFloat"}
                readOnly={add}
              />
            </div>{" "}
          </div>
          <div className="quote-btn">
            <Button
              btnname={"Clear"}
              onclick={handleReset}
              br={4}
              bg={"#fff"}
              border={"1px solid #1e6641"}
              cl={"#1e6641"}
              height={41}
              fs={20}
              width={100}
            />

            <Button
              btnname={add ? "Adding..." : "Add New Row"}
              onclick={handleQuote}
              disabled={add}
              height={41}
              fs={20}
              br={4}
              // width={100}
            />
          </div>
          <div className="table-wrap">
            <Table
              columns={columns}
              dataSource={quote && [...data_1, ...summaryData]}
              pagination={false}
              loading={{
                spinning: add,
                indicator: <div className="spinners"></div>,
              }}
            />
          </div>
          <div className="quote-bottom flexBox">
            <div style={{ width: rem(300) }}>
              <InputField
                label={"c_name"}
                labelName={"Client Org. Name"}
                type={"text"}
                placeholder={"Enter Client Org. Name"}
                fieldData={data_2.c_name}
                setFormData={setFurtherDetail}
                maxLength={50}
                hasError={furtherError.c_name_error}
                readOnly={generate}
                pattern={"onlyAlpha"}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                label={"att"}
                labelName={"Attention to"}
                type={"text"}
                placeholder={"Enter Point of Contact"}
                fieldData={data_2.att}
                setFormData={setFurtherDetail}
                maxLength={50}
                hasError={furtherError.att_error}
                readOnly={generate}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                label={"add"}
                labelName={"Client Org. Address"}
                type={"text"}
                placeholder={"Enter Client Org. Address"}
                fieldData={data_2.add}
                setFormData={setFurtherDetail}
                maxLength={50}
                hasError={furtherError.add_error}
                readOnly={generate}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"date"}
                labelName={"Date"}
                type={"date"}
                placeholder={""}
                fieldData={data_2.date}
                setFormData={setFurtherDetail}
                maxLength={maxDate}
                hasError={furtherError.date_error}
                readOnly={generate}
              />
            </div>{" "}
            <div style={{ width: rem(300) }}>
              <InputField
                label={"dely"}
                labelName={"Delivery Timelines"}
                type={"text"}
                placeholder={"Enter Delivery Timelines"}
                fieldData={data_2.dely}
                setFormData={setFurtherDetail}
                maxLength={50}
                hasError={furtherError.dely_error}
                readOnly={generate}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                label={"ship"}
                labelName={"Mode of Shipment"}
                type={"text"}
                placeholder={"Enter Mode of Shipment"}
                fieldData={data_2.ship}
                setFormData={setFurtherDetail}
                maxLength={50}
                hasError={furtherError.ship_error}
                readOnly={generate}
              />
            </div>
          </div>
          <div className="quote-btn">
            <Button
              btnname={generate ? "GENERATING QUOTE..." : "Generate Quote"}
              onclick={handleGenerate}
              disabled={generate}
              height={49}
              fs={20}
              br={4}
              width={256}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Docx Preview"
        className="feedback-pdf"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        {modalContent}
      </Modal>

      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.submit}
      />
    </section>
  );
};

export default QuoteGenerator;
