import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const AwaitingOrderTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  totalCount,
  defaultPageSize,
}) => {
  const columns = [
    {
      title: "Reference Number",
      dataIndex: "reference_number",
      key: "reference_number",
      width: rem(124),
      className: "",
      sorter: (a, b) =>
        (a.reference_number || "").localeCompare(b.reference_number || ""),
      render: (text) => renderEmptyOrValue(text.substring(4)),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      width: rem(136),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: rem(146),
      render: () => <span className="awo status">Awaiting order</span>,
      className: "",
    },
    {
      title: "Quote Value",
      dataIndex: "currency_value",
      key: "currency_value",
      sorter: (a, b) =>
        (a.currency_value || "").localeCompare(b.currency_value || ""),
      width: rem(104),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => (a.currency || "").localeCompare(b.currency || ""),
      width: rem(114),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Awaiting Order",
      dataIndex: "quotation_time",
      key: "quotation_time",
      sorter: (a, b) => a.quotation_time.localeCompare(b.quotation_time),
      width: rem(114),
      render: (text) => {
        const quoteDate = new Date(text);
        const quoteAgeingDays = !isNaN(quoteDate)
          ? Math.floor((new Date() - quoteDate) / (1000 * 60 * 60 * 24))
          : null;

        return (
          <span>
            {text
              ? quoteAgeingDays !== null
                ? `${quoteAgeingDays} days`
                : "Empty"
              : "Empty"}
          </span>
        );
      },
    },
    {
      title: "Client Team Member",
      dataIndex: "client_person_name",
      key: "client_person_name",
      width: rem(134),
      className: "noPadding",
      style: { padding: 0 },
      render: (text, record) => (
        <span className="df flexColumn align-start">
          {renderEmptyOrValue(text, false)}

          {renderEmptyOrValue(
            record.client_email ? record.client_email.split("<")[0] : "", // Extract email up to '<' character
            false,
            "email"
          )}
        </span>
      ),
      sorter: (a, b) =>
        (a.client_person_name || "").localeCompare(b.client_person_name || ""),
    },
    {
      title: "Enquiry Subject",
      dataIndex: "client_subject",
      key: "client_subject",
      width: rem(174),
      className: "",
      render: (text) => renderEmptyOrValue(text),
      sorter: (a, b) =>
        (a.client_subject || "").localeCompare(b.client_subject || ""),
    },
    {
      title: `Client Enquiry Time`,
      dataIndex: "client_email_time",
      key: "client_email_time",
      className: "",
      render: (text) => renderEmptyOrValue(text?.split(" ")[0]),
      width: rem(134),
      sorter: (a, b) =>
        (a.client_email_time || "").localeCompare(b.client_email_time || ""),
    },
    {
      title: `Quotation Email Time`,
      dataIndex: "quotation_time",
      key: "quotation_time",
      className: "",
      sorter: (a, b) =>
        (a.quotation_time || "").localeCompare(b.quotation_time || ""),
      render: (text) => renderEmptyOrValue(text?.split(" ")[0]),
      width: rem(134),
    },
    // {
    //   title: " # of Follow-ups",
    //   dataIndex: "reminder_statu",
    //   key: "reminder_statu",
    //   width: rem(190),
    //   render: (text) => renderEmptyOrValue(text),
    // },
  ];

  const filteredUsers = useMemo(() => {
    return (users || []).filter((user) => {
      return (
        (user?.reference_number || "")
          .toLowerCase()
          .includes((filteredData?.reference_number || "").toLowerCase()) &&
        (user?.sales_person_name || "")
          .toLowerCase()
          .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
        (user?.client_person_name || "")
          .toLowerCase()
          .includes((filteredData?.client_person_name || "").toLowerCase()) &&
        (user?.client_subject || "")
          .toLowerCase()
          .includes((filteredData?.client_subject || "").toLowerCase()) &&
        (filteredData.reminder_status1
          ? new Date(user.reminder_status) <=
            new Date(filteredData.reminder_status1)
          : true) &&
        (filteredData.last_login1
          ? new Date(user.reminder_status) >=
            new Date(filteredData.reminder_status2)
          : true)
      );
    });
  }, [users, filteredData]);
  useEffect(() => {
    totalCount(filteredUsers.length); // Set total count directly
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default AwaitingOrderTable;
