/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
// import { useRadioGroup } from "@mui/material/RadioGroup";
import Line from "./../../Assets/Quiz/Line.svg";
import upload from "./../../Assets/Quiz/upload.svg";
import Animation from "./../../Assets/Quiz/Animation.gif";
import _http from "../../Utils/Api/_http";
import "./Quiz.scss";
// import QuizDrag from "./QuizDrag";
import SnackBar from "../../Components/SnackBar";
import { useNavigate } from "react-router-dom";
import QuizModel from "./QuizModel";

const Quiz = () => {
  const navigate = useNavigate();

  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    navigate("/SpiraQuiz");
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("Dropped files:", files);
  };
  //Drag
  // State to hold the uploaded files

  //Event for the Upload and recent buttons
  const [activeButton, setActiveButton] = useState(1);

  function Upload() {
    navigate("/SpiraQuiz");
  }
  function Recent(id) {
    setActiveButton(id);
    navigate("/SpiraQuiz/Recent");
  }

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const [questions, setQuestions] = useState([
    {
      title: "Enter the Question Title Here",
      Question: "Enter the Question Here",
      choices: [
        { choice_text: "Option_1", is_correct: false },
        { choice_text: "Option_2", is_correct: false },
        { choice_text: "Option_3", is_correct: false },
        { choice_text: "Option_4", is_correct: false },
      ],
    },
  ]);
  // Function to add a new question

  const uploadPdf = async (file) => {
    if (!file) {
      setToast({ error: true });
      setError("Please select a file");
      setLoading(false);
      // setIsDropped(false);
    } else {
      setLoading(true);
      // setIsDropped(true);
      console.log(file[0]);
      try {
        const formData = new FormData();
        formData.append("file", file[0]);
        const response = await _http.post("/quiz/FileUpload_QA", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        //setting the formdata to state
        const parsedQuestion = response?.data?.result?.map((item) =>
          JSON.parse(item)
        );
        // Array to store the formatted questions
        let formattedData = [];

        // Loop through each question object and format it
        for (let i = 0; i < parsedQuestion.length; i++) {
          let currentQuestion = parsedQuestion[i];

          let formattedQuestion = {
            title: currentQuestion.title,
            Question: currentQuestion.Question,
            choices: [],
          };

          // Loop through the choices array and format them
          for (let j = 0; j < currentQuestion.choices.length; j++) {
            let choice = currentQuestion.choices[j];

            formattedQuestion.choices.push({
              choice_text: choice,
              is_correct: false, // Initially setting is_correct to false
            });
          }

          // Push the formatted question into the formattedData array
          formattedData.push(formattedQuestion);
        }
        setQuestions(formattedData);

        console.log("formatted data", formattedData);

        setOpen(true);

        sessionStorage.setItem("QuizUpload", file[0].name);
        // navigate("/SpiraQuiz/Upload");
        // alert(file);

        setToast(
          (response.data.message && { submit: true }) ||
            (response.data.error && { error: true })
        );
        setError(response.data.message || response.data.error);
      } catch (error) {
        setToast({ error: true });
        setError(error.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <section className="quizz">
        {loading && (
          <div className="loading">
            <img src={Animation} draggable="false" alt="" />
          </div>
        )}
        <div className="q-up">
          <div className="q-conf">
            <span className="b-btn">Configure QR Event</span>
          </div>
          <button className="q-btn">Quiz</button>
          <span className="q-btns">
            <button className="q-btn1">Catalogue</button>
            <button className="q-btn1">Photo</button>
          </span>

          <div className="q-up1">
            <div className="q-btn-mode">
              <button
                className={activeButton === 1 ? "active" : " "}
                onClick={() => {
                  Upload(1);
                }}
              >
                Upload Questions
              </button>
              <button
                className={activeButton === 2 ? "active" : " "}
                onClick={() => {
                  Recent(2);
                }}
              >
                Recent
              </button>
            </div>
            <div className="container">
              <div
                className={`q-upload`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDrop={handleDrop}
                onClick={handleFileUpload}
              >
                <div className="drag1">
                  <img src={upload} alt="up-svg" width={20} />
                  <p>Click to Upload or drag and drop your files</p>
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => {
                  uploadPdf(e.target.files);
                }}
              />
            </div>
          </div>
        </div>

        {/* line  */}

        <div className="q-line">
          <div className="line">
            <img src={Line} />
          </div>

          <h1 className="q-txt">Or</h1>
        </div>

        {/* right side  */}
        <div className="q-down">
          <div className="qf-form" onClick={() => setOpen(true)}>
            <h1>Enter Questions Manually</h1>
            <form className="qf-data">
              <p>Enter Questions Here</p>
              <div className="q-radio">
                <label>
                  <input
                    type="radio"
                    value="option1"
                    name="quizoption"
                    color="red"
                  />
                  A. Enter the option here
                </label>
              </div>
              <div className="q-radio">
                <label>
                  <input type="radio" name="quizoption" value="option2" />
                  B. Enter the option here
                </label>
              </div>
              <div className="q-radio">
                <label>
                  <input type="radio" name="quizoption" value="option3" />
                  C. Enter the option here
                </label>
              </div>
              <div className="q-radio">
                <label>
                  <input type="radio" name="quizoption" value="option3" />
                  D. Enter the option here
                </label>
              </div>
            </form>
          </div>
          {open && (
            <QuizModel
              quizdata={questions}
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
            />
          )}
        </div>

        <SnackBar
          message={errors}
          severity={"error"}
          handleClose={handletoast}
          Open={toast?.error}
        />
        <SnackBar
          message={errors}
          severity={"success"}
          handleClose={handletoast}
          Open={toast?.submit}
        />
      </section>
    </>
  );
};

export default Quiz;
