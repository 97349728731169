import React, { useState, useEffect, useRef } from "react";
import "./QuizMobile.scss";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";
import Button from "../../Components/Button/Button";
import google_img from "../../Assets/QuizMobile/flat-color-icons_google.svg";
import apple_img from "../../Assets/QuizMobile/Apple.svg";
import { useNavigate } from "react-router-dom";
import { rem } from "../../Components/Rem_func";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";
import SnackBar from "../../Components/SnackBar";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

export default function QuizAdmin() {
  const [toast, setToast] = useState({ submit: false, error: false });
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [files, setFiles] = useState(false);
  const fileInputRef = useRef(null);
  const googleLoginRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const poolName = searchParams.get("pool");

    if (poolName) {
      sessionStorage.setItem("poolName", poolName);
    }
  }, []);

  // const handleImageClick = () => {
  //   // Trigger Google login when the image is clicked
  //   if (googleLoginRef.current) {
  //     googleLoginRef.current.click();
  //   }
  // };

  const handleSuccess = async (response) => {
    console.log("Login Success:", response);
    const { credential } = response;

    // Decode the JWT
    const decoded = jwtDecode(credential);

    // Extract name and email
    const userName = decoded.name; // User's name
    const userEmail = decoded.email;

    console.log("User Name:", userName);
    console.log("User Email:", userEmail);

    // Validate phone number
    let userPhone = prompt("Please enter your phone number:");

    // Loop until a valid phone number is entered
    while (!/^\d{10}$/.test(userPhone)) {
      if (userPhone === "" || userPhone === null) {
        alert("Phone number cannot be empty.");
      } else if (!/^\d+$/.test(userPhone)) {
        alert("Please enter only numeric values.");
      } else if (userPhone.length !== 10) {
        alert("Phone number must be exactly 10 digits.");
      }

      // Prompt again for valid phone number
      userPhone = prompt("Please enter a valid phone number:");
    }

    try {
      const res = await _nonAuthHttp.post("/quiz/participants", {
        name: userName,
        email: userEmail,
        phone_number: userPhone,
      });
      console.log(res.data.id);
      sessionStorage.setItem("participantsId", res.data.id);
      sessionStorage.setItem("participantsName", res.data.name);
      setError("Successfully Registered");
      setToast({ submit: true });
      setTimeout(() => {
        navigate("/start-quiz");
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(
        error?.response?.data?.email ||
          error?.response?.data?.name ||
          error?.response?.data?.phone_number
      );
      setToast({ error: true });
    } finally {
      setLoading(false);
    }
  };

  const handleError = () => {
    console.log("Login Failed");
  };

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let pattern;
    if (name === "name") {
      pattern = /^[A-Za-z\s]*$/;
    } else if (name === "email") {
      pattern = /^[a-z0-9.@]*$/;
    } else if (name === "phone_number") {
      pattern = /^[0-9]*$/; // Allow only numbers
    }

    // Allow empty value to let the user delete input
    if (!pattern || value === "" || pattern.test(value)) {
      if (name === "phone_number" && value.length > 10) {
        return; // Stop input if length exceeds 10 digits
      }

      if (name === "email") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
            .replace(/\s/g, "")
            .replace(/[^a-zA-Z0-9.@ ]/g, "")
            .replace(/([,.@])\1+/g, "$1"),
        }));
        return;
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value.replace(/\s+/g, " "),
      }));
    }
  };

  const validateLoginInput = (name, email, phone_number) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (
      name?.length === 0 ||
      email?.length === 0 ||
      phone_number?.length === 0
    ) {
      return "Please fill out all fields";
    }
    if (!emailPattern?.test(email) || /^\d+$/.test(email.split("@")[0])) {
      return "Please enter a valid email address";
    }
    if (phone_number.length < 10) {
      return "Please enter a valid phone number";
    }
    return null;
  };

  const fetchData = async () => {
    const inputError = validateLoginInput(
      formData.name,
      formData.email,
      formData.phone_number
    );

    if (inputError) {
      setToast({ error: true });
      setError(inputError);
      return;
    }
    setLoading(true);
    try {
      const res = await _nonAuthHttp.post("/quiz/participants", formData);
      console.log(res.data.id);
      sessionStorage.setItem("participantsId", res.data.id);
      sessionStorage.setItem("participantsName", res.data.name);
      setError("Successfully Register");
      setToast({ submit: true });
      setTimeout(() => {
        navigate("/start-quiz");
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(
        error?.response?.data?.email ||
          error?.response?.data?.name ||
          error.response?.data?.phone_number
      );
      setToast({ error: true });
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const uploadHandler = async () => {
    if (!file) {
      setToast({ error: true });
      setError("Please select a file");
    } else {
      setFiles(true);
      try {
        const formData = new FormData();
        formData.append("image", file[0]);
        const response = await _nonAuthHttp.post(
          "quiz/ImageUpload_card",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setFile();
        setToast(
          (response.data.message && { submit: true }) ||
            (response.data.error && { error: true })
        );
        setError(response.data.message || response.data.error);
      } catch (error) {
        setToast({ error: true });
        setError(error.message);
      }
      setFiles(false);
    }
  };

  return (
    <div className="m-quiz-main_container">
      <div className="logo-img">
        <img src={logoImg} alt="error" />
      </div>
      <div className="main">
        <div className="inp-section">
          <label className="label_name" for="name">
            Name
          </label>
          <div className="inp">
            <input
              type="text"
              value={formData.name}
              name="name"
              placeholder="Enter your name"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="inp-section">
          <label className="label_name" for="email">
            Email ID
          </label>
          <div className="inp">
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="inp-section">
          <label className="label_name" for="phone">
            Phone Number
          </label>
          <div className="inp">
            <input
              type="text"
              name="phone_number"
              placeholder="Enter your phone number"
              value={formData.phone_number}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="btn_quiz">
          <button
            className="quizBtn"
            onClick={fetchData}
            disabled={loading} // Disable if form is invalid or while loading
          >
            {loading ? "Submitting..." : "Start Quiz"}
          </button>
        </div>
        <div className="or_div">
          <p className="line1"></p>
          <div className="or">Or</div>
          <p className="line1"></p>
        </div>
        <div className="social-media-link">
          <GoogleOAuthProvider clientId="81911954861-hocbassb5i2kuqq23mmh3fl45jd54bc4.apps.googleusercontent.com">
            <div>
              {/* <img
                src={google_img}
                alt="error"
                onClick={handleImageClick}
                style={{ cursor: "pointer" }}
              /> */}
            </div>
            <GoogleLogin
              // ref={googleLoginRef}
              onSuccess={handleSuccess}
              onError={handleError}
              // Hide the GoogleLogin button
            />
          </GoogleOAuthProvider>
          {/* <div>
            <img src={apple_img} alt="error" />
          </div> */}
        </div>

        <div className="or_div">
          <p className="line1"></p>
          <div className="or">Or</div>
          <p className="line1"></p>
        </div>

        <div className="card_detail">
          <button
            onClick={uploadHandler}
            disabled={files}
            style={{ textAlign: "center" }}
          >
            {files ? "uploading..." : "Provide Business Card Details"}
          </button>
          <div className="img_icn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
            >
              <path
                d="M6.83353 8.79987C8.01174 8.79987 8.96686 7.84474 8.96686 6.66654C8.96686 5.48833 8.01174 4.5332 6.83353 4.5332C5.65532 4.5332 4.7002 5.48833 4.7002 6.66654C4.7002 7.84474 5.65532 8.79987 6.83353 8.79987Z"
                fill="#1E6641"
              />
              <path
                d="M4.83366 0L3.61366 1.33333H1.50033C0.766992 1.33333 0.166992 1.93333 0.166992 2.66667V10.6667C0.166992 11.4 0.766992 12 1.50033 12H12.167C12.9003 12 13.5003 11.4 13.5003 10.6667V2.66667C13.5003 1.93333 12.9003 1.33333 12.167 1.33333H10.0537L8.83366 0H4.83366ZM6.83366 10C4.99366 10 3.50033 8.50667 3.50033 6.66667C3.50033 4.82667 4.99366 3.33333 6.83366 3.33333C8.67366 3.33333 10.167 4.82667 10.167 6.66667C10.167 8.50667 8.67366 10 6.83366 10Z"
                fill="#1E6641"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              onClick={handleFileUpload}
            >
              <path
                d="M7.5 12.8334C6.5953 12.8553 5.71881 12.5176 5.06271 11.8944C4.40661 11.2711 4.02444 10.413 4 9.50839V3.57589C4.01682 2.92192 4.29204 2.30125 4.76543 1.84973C5.23881 1.39821 5.8718 1.15262 6.52583 1.16672C7.18087 1.15105 7.81535 1.39594 8.29001 1.84763C8.76466 2.29931 9.04069 2.92088 9.0575 3.57589V9.51422C9.03324 9.91006 8.8589 10.2817 8.57002 10.5535C8.28115 10.8252 7.8995 10.9765 7.50292 10.9765C7.10633 10.9765 6.72469 10.8252 6.43581 10.5535C6.14694 10.2817 5.97259 9.91006 5.94833 9.51422V4.03672C5.94833 3.88201 6.00979 3.73364 6.11919 3.62424C6.22858 3.51485 6.37696 3.45339 6.53167 3.45339C6.68638 3.45339 6.83475 3.51485 6.94415 3.62424C7.05354 3.73364 7.115 3.88201 7.115 4.03672V9.51422C7.12663 9.60891 7.1725 9.69608 7.24395 9.75929C7.31541 9.8225 7.40752 9.8574 7.50292 9.8574C7.59832 9.8574 7.69043 9.8225 7.76188 9.75929C7.83333 9.69608 7.8792 9.60891 7.89083 9.51422V3.57589C7.8727 3.2308 7.7192 2.90678 7.46364 2.67416C7.20809 2.44154 6.87111 2.31909 6.52583 2.33339C6.18155 2.32066 5.84606 2.44382 5.59178 2.67627C5.33749 2.90873 5.18481 3.23185 5.16667 3.57589V9.50839C5.19095 10.1036 5.45015 10.665 5.88749 11.0696C6.32483 11.4741 6.90466 11.6888 7.5 11.6667C8.09534 11.6888 8.67517 11.4741 9.11251 11.0696C9.54985 10.665 9.80905 10.1036 9.83333 9.50839V3.57589C9.83333 3.42118 9.89479 3.27281 10.0042 3.16341C10.1136 3.05402 10.262 2.99256 10.4167 2.99256C10.5714 2.99256 10.7198 3.05402 10.8291 3.16341C10.9385 3.27281 11 3.42118 11 3.57589V9.50839C10.9756 10.413 10.5934 11.2711 9.93729 11.8944C9.28119 12.5176 8.4047 12.8553 7.5 12.8334Z"
                fill="#1E6641"
              />
            </svg>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                setFile(e.target.files);
              }}
            />
          </div>
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
}
