import React from 'react';
// import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import {rem} from '../../../Components/Rem_func'


const SalesBieChart = () => {

  const datas = [
    { name: 'Successful conversion', value: 5240, color: '#2D8B48' },
    { name: 'Unsuccessful conversion', value: 2666, color: '#6ABF69' },
    { name: 'Quoted value Missing', value: 709, color: '#B4D4B4' },
    { name: 'Missed Quoted', value: 689, color: '#D4E7D4' },
  ];
  const data = {
    labels: [
      'Successful conversion',
      'Unsuccessful conversion',
      'Quoted value Missing',
      'Missed Quoted'
    ],
    datasets: [
      {
        data: [5240, 2666, 709, 689],
        backgroundColor: ['#1E6641','#1E6641', '#558B70', '#CEDDD5'],
        hoverBackgroundColor: ['#1E6641','#1E6641', '#558B70', '#CEDDD5'],
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend, as we are customizing it
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((sum, value) => sum + value, 0);
            const currentValue = dataset.data[tooltipItem.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${currentValue} (${percentage}%)`;
          }
        }
      }
    },
    cutout: '60%', // Controls the size of the hole in the middle
  };


  return (
    <div className="sales-bie-chart">
      <div className="chart-title">By TAT bucket</div>
      <div className="chart-wrapper">
        <div className="legend-wrapper">
          {datas.map((entry, index) => (
            <div key={`legend-${index}`} style={{paddingTop:'5px'}}>
            <div className="legend-item">
              <div
                className="legend-color"
                style={{ backgroundColor: entry.color }}
                />
              <p>{entry.name}</p>
            </div>
              <p className='ktg'>{`${entry.value} (${((entry.value / 9240) * 100).toFixed(2)}%)`}</p>
            </div>
          ))}
        </div>
        <div style={{width:rem(174), height:rem(171)}}>
            <Doughnut data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default SalesBieChart;
