import React from "react";
import "./UserHome.scss";
import { Dropdown, Space } from "antd";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { rem } from "../../../Components/Rem_func";
// Register components
ChartJS.register(ArcElement, Tooltip, Legend);
const UserHome = () => {
  // const pieParams = { height: 143, width: 143, margin: { right: 5 } };
  const data = {
    labels: ["Active", "Inactive"],
    datasets: [
      {
        data: [63, 25], // Your data values
        backgroundColor: [
          "rgba(30, 102, 65, 1)",
          "rgba(99, 200, 155, 1)",
          // "rgba(239, 244, 251, 1)",
        ], // Colors for each slice
        hoverBackgroundColor: [
          "rgba(30, 102, 65, 1)",
          "rgba(99, 200, 155, 1)",
          // "rgba(239, 244, 251, 1)",
        ], // Hover colors
        borderWidth: 1,
        borderColor: "rgba(239, 244, 251, 1)", // Border color for slices
      },
    ],
  };
  const items = [
    {
      key: "1",
      label: <span>Dubai</span>,
    },
    {
      key: "2",
      label: <span>Abudhabi</span>,
    },
    {
      key: "3",
      label: <span>Oman</span>,
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw}%`;
          },
        },
      },
      legend: {
        display: false, // Remove the legend labels
      },
    },
  };
  return (
    <div className="user_home">
      <div className="upper">
        <p className="userM">User Management</p>
        {/* <div className="branch">
          <Dropdown
            menu={{
              items,
            }}
          >
            <Space>
              Select Branch
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(19)}
                height={rem(19)}
                viewBox="0 0 19 19"
                fill="none"
              >
                <path
                  d="M13.459 7.91667L9.50065 11.875L5.54232 7.91667L13.459 7.91667Z"
                  fill="#AAAAAA"
                />
              </svg>
            </Space>
          </Dropdown>
        </div> */}
      </div>

      <div className="mid" style={{ width: rem(122), height: rem(122) }}>
        <Pie data={data} options={options} />
      </div>

      <div className="below">
        <div className="com">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(8)}
            height={rem(8)}
            viewBox="0 0 8 8"
            fill="none"
            style={{ marginTop: rem(5), marginRight: rem(8) }}
          >
            <circle cx="4" cy="4" r="4" fill="#1E6641" />
          </svg>
          <div>
            <p className="enq">Active</p>
            <p className="per">63</p>
          </div>
        </div>
        <div className="com">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(8)}
            height={rem(8)}
            viewBox="0 0 8 8"
            fill="none"
            style={{ marginTop: rem(5), marginRight: rem(8) }}
          >
            <circle cx="4" cy="4" r="4" fill="#63C89B" />
          </svg>
          <div>
            <p className="enq">Inactive</p>
            <p className="per">25</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHome;
