import React from "react";
import DraggableTable from "../../Components/TableDrag_func";

const QualityTable = () => {
  const columns = [
    {
      title: "Reference Number",
      dataIndex: "Reference_number",
      key: "Reference_number",
    },
    {
      title: "Sales Team Member",
      dataIndex: "Sales_team_member",
      key: "Sales_team_member",
    },
  ];

  const filteredUsers = [
    { Reference_number: "hello", Sales_team_member: "world" },
  ];

  return (
    <>
      <DraggableTable
        baseColumns={columns}
        filteredUsers={filteredUsers}
        loading={false}
      />
    </>
  );
};

export default QualityTable;
