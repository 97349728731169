import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Modal } from "antd";
import "../SalesTracker/table.scss";
import _http from "../../Utils/Api/_http";
import view from "../../Assets/feedback/view.png";
import { rem } from "../../Components/Rem_func";
import moment from "moment";
import DraggableTable from "../../Components/TableDrag_func";
import { setdatacount } from "./../../Redux/Reducers";
import { useDispatch } from "react-redux";

const FeedbackTable = ({ filteredData, totalCount, defaultPageSize }) => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const dispatch = useDispatch();

  // Columns configuration for Ant Design Table
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await _http.get(`/api/get_all_feedback_data`);
      setFeedback(response?.data);
      dispatch(setdatacount(response.data.length));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const filteredFeedBack = useMemo(() => {
    return (feedback || [])?.filter((feedbackItem) => {
      const formatDate = (date) =>
        moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");

      return (
        (feedbackItem?.company_name || "")
          .toLowerCase()
          .includes((filteredData?.company_name || "").toLowerCase()) &&
        (feedbackItem?.client_name || "")
          .toLowerCase()
          .includes((filteredData?.client_name || "").toLowerCase()) &&
        (feedbackItem?.client_disignation || "")
          .toLowerCase()
          .includes((filteredData?.client_disignation || "").toLowerCase()) &&
        (feedbackItem?.email_address || "")
          .toLowerCase()
          .includes((filteredData?.email_address || "").toLowerCase()) &&
        (feedbackItem?.telephone_number || "")
          .toLowerCase()
          .includes((filteredData?.telephone_number || "").toLowerCase()) &&
        (feedbackItem?.quality_rate || "")
          .toLowerCase()
          .includes((filteredData?.quality_rate || "").toLowerCase()) &&
        (feedbackItem?.services_experience_rate || "")
          .toLowerCase()
          .includes(
            (filteredData?.services_experience_rate || "").toLowerCase()
          ) &&
        (feedbackItem?.technical_enquires_rate || "")
          .toLowerCase()
          .includes(
            (filteredData?.technical_enquires_rate || "").toLowerCase()
          ) &&
        (feedbackItem?.team_communication_rate || "")
          .toLowerCase()
          .includes(
            (filteredData?.team_communication_rate || "").toLowerCase()
          ) &&
        (feedbackItem?.team_help_rate || "")
          .toLowerCase()
          .includes((filteredData?.team_help_rate || "").toLowerCase()) &&
        (feedbackItem?.product_quality_punctuality_rate || "")
          .toLowerCase()
          .includes(
            (filteredData?.product_quality_punctuality_rate || "").toLowerCase()
          ) &&
        (feedbackItem?.customer_statisfaction_rate || "")
          .toLowerCase()
          .includes(
            (filteredData?.customer_statisfaction_rate || "").toLowerCase()
          ) &&
        (feedbackItem?.service_provider_rate || "")
          .toLowerCase()
          .includes(
            (filteredData?.service_provider_rate || "").toLowerCase()
          ) &&
        (feedbackItem?.about_team_product_service || "")
          .toLowerCase()
          .includes(
            (filteredData?.about_team_product_service || "").toLowerCase()
          ) &&
        formatDate(feedbackItem?.form_date || "").includes(
          filteredData?.form_date || ""
        )
      );
    });
  }, [feedback, filteredData]);

  // to render the datas in the table
  const renderEmptyOrValue = (text, TT, cln) => {
    return text ? (
      <span className={cln} style={{ textTransform: TT && "capitalize" }}>
        {text}
      </span>
    ) : (
      <span className={"empty"}>Empty</span>
    );
  };

  const fetchFile = async (ids, endpoint) => {
    setLoading(true);
    try {
      const response = await _http.get(`/api/${endpoint}/${ids}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      setModalContent(
        <embed
          width={"100%"}
          height={"100%"}
          src={URL.createObjectURL(blob)}
          type="application/pdf"
        />
      );
      setModalVisible(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: "Time Stamp",
      dataIndex: "form_timestamp",
      key: "form_timestamp",
      width: rem(134),
      className: " ",
      sorter: (a, b) =>
        (a.form_timestamp || "").localeCompare(b.form_timestamp || ""),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Date",
      dataIndex: "form_date",
      key: "form_date",
      className: " ",
      sorter: (a, b) => (a.form_date || "").localeCompare(b.form_date || ""),
      width: rem(104),
      render: (text) =>
        renderEmptyOrValue(
          moment(text, "MM/DD/YYYY").format("DD/MM/YYYY"),
          true
        ),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      className: " ",
      sorter: (a, b) =>
        (a.company_name || "").localeCompare(b.company_name || ""),
      width: rem(120),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      className: " ",
      sorter: (a, b) =>
        (a.client_name || "").localeCompare(b.client_name || ""),
      width: rem(124),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Client Designation ",
      dataIndex: "client_disignation",
      key: "client_disignation",
      className: " ",

      sorter: (a, b) =>
        (a.client_disignation || "").localeCompare(b.client_disignation || ""),
      width: rem(134),
      render: (text) => renderEmptyOrValue(text, true),
    },
    {
      title: "Telephone Number",
      dataIndex: "telephone_number",
      key: "telephone_number",
      className: " ",
      sorter: (a, b) =>
        (a.telephone_number || "").localeCompare(b.telephone_number || ""),
      width: rem(134),
      render: (text) => renderEmptyOrValue(text, true),
    },

    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email_address",
      className: " ",

      sorter: (a, b) =>
        (a.email_address || "").localeCompare(b.email_address || ""),
      width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Quality rate",
      dataIndex: "quality_rate",
      key: "quality_rate",
      width: rem(104),
      sorter: (a, b) =>
        (a.quality_rate || "").localeCompare(b.quality_rate || ""),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Service experience rate",
      dataIndex: "services_experience_rate",
      key: "services_experience_rate",
      sorter: (a, b) =>
        (a.services_experience_rate || "").localeCompare(
          b.services_experience_rate || ""
        ),

      width: rem(154),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Technical enquires rate",
      dataIndex: "technical_enquires_rate",
      key: "technical_enquires_rate",
      sorter: (a, b) =>
        (a.technical_enquires_rate || "").localeCompare(
          b.technical_enquires_rate || ""
        ),

      width: rem(134),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Team Communication rate",
      dataIndex: "team_communication_rate",
      key: "team_communication_rate",
      sorter: (a, b) =>
        (a.team_communication_rate || "").localeCompare(
          b.team_communication_rate || ""
        ),

      width: rem(174),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Team help rate",
      dataIndex: "team_help_rate",
      key: "team_help_rate ",
      sorter: (a, b) =>
        (a.team_help_rate || "").localeCompare(b.team_help_rate || ""),

      width: rem(134),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Product quality punctuality rate",
      dataIndex: "product_quality_punctuality_rate",
      key: "product_quality_punctuality_rate",
      sorter: (a, b) =>
        (a.product_quality_punctuality_rate || "").localeCompare(
          b.product_quality_punctuality_rate || ""
        ),

      width: rem(154),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Customer Satisfaction rate",
      dataIndex: "customer_statisfaction_rate",
      key: "customer_statisfaction_rate",
      sorter: (a, b) =>
        (a.customer_statisfaction_rate || "").localeCompare(
          b.customer_statisfaction_rate || ""
        ),

      width: rem(154),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Service provider rate",
      dataIndex: "service_provider_rate",
      key: "service_provider_rate",
      className: " ",
      sorter: (a, b) =>
        (a.service_provider_rate || "").localeCompare(
          b.service_provider_rate || ""
        ),

      width: rem(134),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "About team product service",
      dataIndex: "about_team_product_service",
      key: "about_team_product_service",
      sorter: (a, b) =>
        (a.about_team_product_service || "").localeCompare(
          b.about_team_product_service || ""
        ),

      width: rem(154),
      className: " ",

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Other feedback",
      dataIndex: "other_feedback",
      key: "other_feedback",
      className: " ",
      sorter: (a, b) =>
        (a.other_feedback || "" || "").localeCompare(b.other_feedback || ""),

      width: rem(200),

      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      width: rem(64),
      render: (text, record) => (
        <div
          onClick={() => fetchFile(text, "download_docx")}
          style={{ cursor: "pointer" }}
        >
          <img
            src={view}
            alt="img"
            style={{ width: rem(24), height: rem(13) }}
          />
        </div>
      ),
    },
    {
      title: "Email Screenshot",
      dataIndex: "id",
      key: "id",
      width: rem(104),
      render: (text, record) => (
        <div
          onClick={() => fetchFile(text, "get_feedback_data")}
          style={{ cursor: "pointer" }}
        >
          <img
            src={view}
            alt="img"
            style={{ width: rem(24), height: rem(13) }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    totalCount(filteredFeedBack.length); // Set total count directly
  }, [filteredFeedBack, totalCount]);
  return (
    <div>
      <DraggableTable
        filteredUsers={filteredFeedBack}
        loading={loading}
        baseColumns={columns}
        drag={false}
        defaultPageSize={defaultPageSize}
      />

      <Modal
        // title="Docx Preview"
        className="feedback-pdf"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default FeedbackTable;
