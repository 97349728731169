import React, { useState, useEffect, useCallback } from "react";
import "./SalesTracker.scss";
import EditPopup from "../STeditPopup/STeditPopup";
import EditPopup1 from "../STeditPopup/STeditPopup1";
import down from "../../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../../Assets/EnquiresIcon/filteron.svg";
import greenDown from "../../../../Assets/EnquiresIcon/green_down.svg";
import _http from "../../../../Utils/Api/_http";
import FilterEnquires from "../Filter/FilterEnquires";
import { Popover, Space } from "antd";
import SnackBar from "../../../../Components/SnackBar";
import SizeChanger from "../../../../Components/PageSizeChanger/SizeChanger";
// import { rem } from "../../../../Components/Rem_func";
import { setdatacount } from "../../../../Redux/Reducers";
import { useDispatch } from "react-redux";

const SalesTracker = ({
  title,
  UserDataTable,
  api,
  AwaitingOrder,
  OrderPlaced,
  AwaitingQuotes,
}) => {
  // State variables
  const [open, setOpen] = React.useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [filteredData, setFilteredData] = useState({
    reference_number: "",
    sales_person_name: "",
    client_person_name: "",
    client_subject: "",
    reminder_status1: "",
    reminder_status2: "",
  });

  const [deleteBtn, setDeleteBtn] = useState(false);
  const [updateBtn, setUpdateBtn] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [toast, setToast] = useState({ delete: false, error: false });
  const [errors, setError] = useState("");
  const dispatch = useDispatch();

  // Function to handle closing snackbar
  const handletoast = () => {
    setToast({ error: false, delete: false });
  };

  // Function to handle delete operation
  const handleDelete = async (reference_number, sales_mail, index) => {
    setDeleteBtn(true);
    try {
      await _http.post(`/api/delete`, {
        rfrnc_num: reference_number,
        sales_mail: sales_mail,
      });
      handleClose();
      setError("Successfully deleted");
      setToast({ delete: true });
      fetchData();
    } catch (error) {
      console.log(error);
      setToast({ error: true });
      setError(error.message);
    }
    setDeleteBtn(false);
  };

  // Function to handle update operation
  const handleUpdate = async (ref_num, email, updatedData) => {
    const updatedDatas = JSON.stringify(updatedData);
    setUpdateBtn(true);
    try {
      await _http.put(`/api/update/${ref_num}`, {
        email: email,
        jsondata: updatedDatas,
      });
      setError("Successfully updated");
      setToast({ delete: true });
      fetchData();
    } catch (error) {
      setToast({ error: true });
      setError(error.message);
    }
    setUpdateBtn(false);
    handleClose();
  };

  const handleOrder = async (reference_number, updatedData, email) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("jsondata", JSON.stringify(updatedData));
    setUpdateBtn(true);
    try {
      await _http.put(`/api/update/${reference_number}`, formData);
      setError("Successfully updated");
      setToast({ delete: true });
      handleClose();
      fetchData();
    } catch (error) {
      setToast({ error: true });
      setError(error.message);
    }
    setUpdateBtn(false);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await _http.get(api);

      if (Array.isArray(response.data) && response.data.every(Array.isArray)) {
        const flattenedData = response.data.flat();
        setUsers(flattenedData.flat());
        dispatch(setdatacount(flattenedData?.flat()?.length));
      } else {
        setUsers(response.data);
      }
    } catch (error) {
      setToast({ error: true });
      setError("Error fetching data:", error?.message);
      console.log(error);
    }
    setLoading(false);
  }, [api, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Effect to fetch data on component mount

  // Function to handle opening user details
  const handleOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  // toast function for update
  const handleUpdateToast = (submit, error, message) => {
    setToast({
      submit: submit,
    });
    setToast({
      error: error,
    });
    setError(message);
  };

  // Function to handle closing user details
  const handleClose = () => setOpen(false);

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to handle filter change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  // Function to handle filter
  const handleFilter = (data) => {
    setFilteredData(data);
    const isFilterOn =
      data.reference_number !== "" ||
      data.sales_person_name !== "" ||
      data.client_person_name !== "" ||
      data.client_subject !== "" ||
      data.reminder_status1 !== "" ||
      data.reminder_status2 !== "";
    setFilterOn(isFilterOn);
  };

  // to render the datas in the table
  const renderEmptyOrValue = (text, TT, cln) => {
    return text ? (
      <span className={cln} style={{ textTransform: TT && "capitalize" }}>
        {text}
      </span>
    ) : (
      <span className={"empty"}>Empty</span>
    );
  };

  const [count, setCount] = useState("50");
  const [totalcount, setTotalCount] = useState("0");
  const PageCount = ["10", "20", "50", "100"];

  const items = PageCount.map((page) => ({
    key: page,
    label: (
      <div
        style={{ width: "100%" }}
        className="pageCount"
        onClick={() => setCount(page)}
      >
        {page}
      </div>
    ),
  }));

  // JSX rendering
  return (
    <section>
      <div className="container1">
        <div className="container-head df">
          <p className="title">{title}</p>
          <div className="total-count-box">{totalcount}</div>
        </div>
        <div className="container-head2 df">
          <SizeChanger count={count} items={items} />
          <Popover
            trigger="click"
            open={openfilter}
            placement="bottomRight"
            onOpenChange={handleOpenChange}
            content={<FilterEnquires onFilter={handleFilter} hide={hide} />}
          >
            <Space>
              <span
                className="filter-head"
                data-tooltip-id="tooltip-arrow"
                data-tooltip-content="Filter"
              >
                <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                <img src={filterOn ? greenDown : down} alt="icon" />
              </span>
            </Space>
          </Popover>
        </div>
        <UserDataTable
          handleOpen={handleOpen}
          users={users}
          filteredData={filteredData}
          loading={loading}
          renderEmptyOrValue={renderEmptyOrValue}
          defaultPageSize={count}
          totalCount={setTotalCount}
        />
        {open &&
          selectedUser &&
          (AwaitingOrder || OrderPlaced ? (
            <EditPopup1
              open={open}
              user={selectedUser}
              handleClose={handleClose}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              handleOrder={handleOrder}
              deletingFun={deleteBtn}
              updatingFun={updateBtn}
              handleUpdateToast={handleUpdateToast}
              AwaitingOrder={AwaitingOrder}
            />
          ) : (
            <EditPopup
              open={open}
              user={selectedUser}
              handleClose={handleClose}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              deletingFun={deleteBtn}
              updatingFun={updateBtn}
              handleUpdateToast={handleUpdateToast}
              AwaitingQuotes={AwaitingQuotes}
              handleOrder={handleOrder}
              AwaitingOrder={AwaitingOrder}
            />
          ))}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.delete}
      />
    </section>
  );
};

export default SalesTracker;
