import React from "react";
import underconstruction from "../Assets/UnderConstruction.svg";

const UnderConstruction = () => {
  return (
    <section>
      <div className="df notaccess">
        <img
          src={underconstruction}
          alt="Not Access"
          style={{ height: "50%" }}
        />
      </div>
    </section>
  );
};

export default UnderConstruction;
