import { React, useEffect, useState } from "react";
import { Popover, Space } from "antd";
import searchIcon from "./../../Assets/UserIcon/usersearchicon.svg";
import filter from "./../../Assets/EnquiresIcon/filter.svg";
import filteron from "./../../Assets/EnquiresIcon/filteron.svg";
import green_down from "./../../Assets/EnquiresIcon/green_down.svg";
import Delete from "./../../Assets/Quiz/Delete.svg";
import down from "./../../Assets/EnquiresIcon/down.svg";
import Pdf from "./../../Assets/Quiz/PDF.svg";
import document from "./../../Assets/Quiz/PDF.svg";
import { useNavigate } from "react-router-dom";
import _http from "../../Utils/Api/_http";
import SnackBar from "../../Components/SnackBar";

const QuizRecent = () => {
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [activeButton, setActiveButton] = useState(1);
  const [filterOn, setFilterOn] = useState(false);
  const [apidata, setApidata] = useState([]);
  // fetching api
  useEffect(() => {
    const RecentQuestion = async () => {
      try {
        const response = await _http.get("/quiz/Get-question-pools");
        // console.log(response.data);
        setApidata(response.data);
        setToast(
          (response.data.message && { submit: true }) ||
            (response.data.error && { error: true })
        );
        setError(response.data.message || response.data.error);
      } catch (er) {
        // console.log(er + "error");
        setToast({ error: true });
        setError(er.message);
      }
    };
    RecentQuestion();
  }, []);

  const navigate = useNavigate();
  function Upload(id) {
    navigate("/SpiraQuiz");
    setActiveButton(id);
  }
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  function Recent(id) {
    setActiveButton(id);
  }
  function deleteQuestionPool(id) {
    const deleteQuestion = apidata.filter((_, i) => i !== id);
    if (apidata.length === 1) {
      setApidata(...apidata);
    } else {
      setApidata(deleteQuestion);
    }
  }
  return (
    <section className="up-31">
      <div className="data1">
        <div className="btn1">
          <div className="q-btn-mode1">
            <button
              className={activeButton === 2 ? "active1" : " "}
              onClick={() => {
                Upload(1);
              }}
            >
              Upload Questions
            </button>
            <button
              className={activeButton === 1 ? "active1" : " "}
              onClick={() => {
                Recent(2);
              }}
            >
              Recent
            </button>
          </div>
          <div className="quiz-sh-fl">
            <div className="user-search df">
              <input
                // type="text"
                placeholder="Search Users"
                // value={filteredData?.user || ""}
                // onChange={handleInputChange}
                name="user"
              />
              <img src={searchIcon} alt="icon" />
            </div>
            <Popover
              trigger="click"
              open={true}
              placement="bottomRight"
              // onOpenChange={handleOpenChange}
              // content={
              //   <FilterUser
              //     // hide={hide}
              //     // roles={roles}
              //     // names={names}
              //     // userList={userList}
              //     // filteredData={filteredData}
              //     // setFilteredData={setFilteredData}
              //   />
              // }
            >
              <Space>
                <span
                  className="filter-head"
                  data-tooltip-id="tooltip-arrow"
                  data-tooltip-content="Filter"
                >
                  <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                  <img src={filterOn ? green_down : down} alt="icon" />
                </span>
              </Space>
            </Popover>
          </div>
        </div>
        <div className="up-box1">
          {/* <QuizDrag Comp={clicked} /> */}
          {apidata.map((item, index) => (
            <div className="q-details" key={index}>
              <div className="q-title">
                <img src={document} alt="" />
                <h1>
                  {item.pool_name} <p>2m ago</p>
                </h1>
                <div className="q-para">
                  <p>
                    Scheduled On <span>{item.schedule_time}</span>
                  </p>

                  <p>
                    Duration
                    <span>{item.duration_in_minutes}</span>
                  </p>

                  <p>
                    No of Questions
                    <span>{item.question_count}</span>
                  </p>
                  <img
                    src={Delete}
                    alt="delete"
                    onClick={() => deleteQuestionPool(index)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </section>
  );
};

export default QuizRecent;
