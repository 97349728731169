import { Modal } from "antd";
import Button from "../../Components/Button/Button";
import { React, useEffect, useState } from "react";
import { rem } from "../../Components/Rem_func";
import Addicon from "./../../Assets/Quiz/Add Icon.svg";
import Delete from "../../Assets/Quiz/Delete.svg";
import SnackBar from "../../Components/SnackBar";
import { useNavigate } from "react-router-dom";
const QuizModel = ({ quizdata, open, setOpen, handleClose }) => {
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const navigate = useNavigate();
  const [formdata, setFormdata] = useState([
    {
      title: "Enter the Question Title Here",
      Question: "Enter the Question Here",
      choices: [
        { choice_text: "Option_1", is_correct: false },
        { choice_text: "Option_2", is_correct: false },
        { choice_text: "Option_3", is_correct: false },
        { choice_text: "Option_4", is_correct: false },
      ],
    },
  ]);
  //add question
  const addQuestionModel = () => {
    const newQuestion = {
      Question: `Enter the new question here`, // New question placeholder
      choices: [
        { choice_text: "Option_1", is_correct: false },
        { choice_text: "Option_2", is_correct: false },
        { choice_text: "Option_3", is_correct: false },
        { choice_text: "Option_4", is_correct: false },
      ], // Default options
    };
    setFormdata([...formdata, newQuestion]); // Add the new question to the state
  };
  //delete question
  const deleteQuestionModel = (index) => {
    const updatedQuestions = formdata.filter((_, i) => i !== index); // Remove the question by index
    setFormdata([...updatedQuestions]); // Update the state with the remaining questions
  };
  // Define the state to hold the content
  // const [content, setContent] = useState([
  //   {
  //     id: 0,
  //     title: "",
  //     question: "",
  //     answer: [""],
  //   },
  // ]);
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  //question data
  function textvalidate(e, index) {
    const newFormData = [...formdata];
    newFormData[index].Question = e.target.value;
    setFormdata(newFormData);
  }
  //whole data
  function questionvalidate(e, index) {
    const { value } = e.target;
    const values = [...formdata];
    values[index].title = value;
    setFormdata(values);
    console.log("Data received ", values);
  }

  //handle Options
  function handleOption(e, index, choiceIndex) {
    const newFormData = [...formdata];
    newFormData[index].choices = newFormData[index].choices.map(
      (choice, i) => ({
        ...choice,
        is_correct: i === choiceIndex,
      })
    );
    setFormdata(newFormData);
    console.log("Radio button event recorded:", newFormData);
  }
  console.log("formdata", formdata);

  const HandleSubmit = () => {
    const allQuestionsAnswered = formdata.every((Question) => {
      const hasAnswer = Question.choices.some((choice) => choice.is_correct);
      return hasAnswer;
    });
    if (allQuestionsAnswered) {
      // Make API call to submit the quiz
      // ...
      navigate("/SpiraQuiz/Upload");
      setToast(
        { submit: true},
        "All questions answered, submitting quiz..."
      );
      sessionStorage.setItem("formData",JSON.stringify(formdata));
    } else {
      setToast(
        { error: true },
        "Please answer all questions before submitting."
      );
      setError(true)
    }
  };
  //handle data copying
  useEffect(() => {
    setFormdata(quizdata);
  }, [quizdata]);

  return (
    <Modal
      centered
      open={open}
      onCancel={handleClose}
      className="edit-popup"
      width={rem(1300)}
      height={rem(700)}
    >
      <div className="model-down">
        <div className="model-title">
          <label htmlFor="title">
            <input
              type="text"
              name="title"
              className="title"
              value={formdata[0]?.title}
              onChange={(e) => {
                questionvalidate(e, 0);
              }}
            />
          </label>

          <div className="model-duration">
            <h1 htmlFor="Duration">
              Duration
              <input
                type="text"
                name="Duration"
                placeholder="30"
                maxLength={2}
                minLength={2}
                value={30}
                className="duration"
                readOnly={true}
                suppressContentEditableWarning={true}
              />
              Mins
            </h1>
            <h1>
              No. of questions
              <input
                type="text"
                contentEditable="false"
                placeholder="X/X"
                value={formdata.length}
                className="duration"
                readOnly={true}
                suppressContentEditableWarning={true}
              />
            </h1>
          </div>
        </div>

        {/* model-section  */}
        <div className="model-form">
          {formdata?.map((info, index) => (
            <>
              <form className="model-data" key={index}>
                <label htmlFor="questionname">
                  {index + 1 + "."}
                  <input
                    type="text"
                    name="questionname"
                    id="questionname"
                    value={info.Question}
                    onChange={(e) => {
                      textvalidate(e, index);
                    }}
                  />
                </label>
                {info?.choices.map((op, id) => (
                  <div className="model-radio">
                    <label key={id}>
                      <input
                        type="radio"
                        value={op.choice_text}
                        name={`quizoption_${index}`}
                        color="red"
                        // onChange={formvalidate}
                        contentEditable="true"
                        checked={op.is_correct}
                        onChange={(e) => handleOption(e, index, id)}
                      />
                      {id + 1 + ". "}
                      {op.choice_text}
                    </label>
                  </div>
                ))}
              </form>
              {formdata?.length > 1 && (
                <span className="quizmoddel">
                  <img
                    src={Delete}
                    alt=""
                    onClick={() => deleteQuestionModel(index)}
                  />
                </span>
              )}
            </>
          ))}
          {formdata.length >= 2 ? (
            <span className="quizmoddel">
              <img src={Addicon} alt="" onClick={addQuestionModel} />
            </span>
          ) : (
            <span className="quizmoddel">
              <img
                src={Delete}
                alt=""
                onClick={() => {
                  alert("UnSucessfull Attempt");
                }}
              />
              <img src={Addicon} alt="" onClick={addQuestionModel} />
            </span>
          )}
        </div>
        <div className="quizmodelbtns">
          <Button
            btnname="Preview"
            bg="#FFFFFF"
            cl="#1E6641"
            width={130}
            height={50}
            fs={14}
            fw={500}
            border={`${rem(2)} solid #1E6641`}
          />
          <Button
            btnname="Submit"
            width={170}
            height={50}
            fs={14}
            fw={500}
            border={`${rem(2)} solid #CDD3D8`}
            onclick={() => HandleSubmit()}
          />
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </Modal>
  );
};

export default QuizModel;
