import React, { useState, useEffect } from "react";
import "./STeditPopup.scss";
import { Modal } from "antd";
import Button from "../../../../Components/Button/Button";
import InputField from "../../../../Components/InputField/InputField";
import { rem } from "../../../../Components/Rem_func";

const EditPopup = ({
  user,
  handleClose,
  open,
  handleDelete,
  handleUpdate,
  handleOrder,
  deletingFun,
  updatingFun,
  handleUpdateToast,
  AwaitingOrder,
}) => {
  const formatDate = (dateString) => {
    return dateString?.split(" ")[0];
  };

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();

  // State to manage user data in the form
  const [userData, setUserData] = useState({
    reference_number: user.reference_number,
    sales_person_name: user.sales_person_name,
    client_person_name: user.client_person_name,
    client_email: user.client_email,
    quotation_time: user?.quotation_time,
    currency: user.currency,
    currency_value: user.currency_value,
    order_value: user.order_value,
    order_date: user.order_date_time ? user.order_date_time.split(" ")[0] : "",
  });

  const [furtherError, setFurtherErrors] = useState({});

  // Function to handle the update button click
  //   const handleUpdateClick = () => {
  //     const emailname = sessionStorage.getItem("Name");
  //     // Prepare updated data
  //     const updatedData = {
  //       reference_number: userData.reference_number,
  //       sales_person_name: userData.sales_person_name,
  //       sales_mail: userData.sales_mail,
  //       client_person_name: userData.client_person_name,
  //       client_email: userData.client_email,
  //       // ack_time: userData.ack_time,
  //       // quotation_time: userData.quotation_time,
  //       client_cc: userData.client_cc,
  //       client_subject: userData.client_subject,
  //       client_email_time: userData.client_email_time,
  //       sales_email_time: userData.sales_email_time,
  //     };
  //     // Call the handleUpdate function
  //     handleUpdate(user.reference_number, emailname, updatedData);
  //   };
  // Function to handle the delete button click
  // const handleDeleteClick = () => {
  //   handleDelete(user.reference_number, user.sales_mail);
  // };
  const handleOrderPlaced = async () => {
    const requiredFields = [
      // "reference_number",
      // "sales_person_name",
      // "sales_mail",
      // "client_person_name",
      // "client_email",
      // "ack_time",
      // "quotation_time",
      // "client_cc",
      // "client_subject",
      // "sales_email_time",
      // "client_email_time",
      "order_value",
      "order_date",
    ];
    const emptyFields = [];

    setFurtherErrors((prevData) => {
      const newData = { ...prevData };
      requiredFields.forEach((field) => {
        if (!userData[field] || userData[field].toString().trim() === "") {
          newData[`${field}_error`] = true;
          emptyFields.push(field);
        } else {
          newData[`${field}_error`] = false;
        }
      });
      return newData;
    });

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });
    if (emptyFields.length > 0) {
      console.log("All fields are required");
      handleUpdateToast(false, true, "All fields are required");

      return;
    }

    if (!userData.order_value || !userData.order_date) {
      return;
    } else {
      const updateddata = {
        reminder_status: "order_placed",
        order_value: userData.order_value,
        order_date_time: userData.order_date,
      };
      handleOrder(user.reference_number, updateddata, user.sales_mail);
    }
  };
  useEffect(() => {
    if (open) {
      const firstFocusableElement = document.querySelector(".user-popup input");
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [open]);
  return (
    <>
      <Modal
        centered
        open={open}
        // onOk={() => setOpen(true)}
        onCancel={handleClose}
        width={rem(808)}
        className="edit-popup"
      >
        <div className="user-popup">
          {/* Input fields for user details */}
          <div className="flexBox popup">
            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"reference_number"}
                labelName={"Reference Number"}
                type={"text"}
                placeholder={""}
                fieldData={userData.reference_number}
                //setFormData={setUserData}
                //maxLength={50}
                //hasError={furtherError.reference_number_error}
                readOnly={true}
              />
            </div>

            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"sales_person_name"}
                labelName={"Sales Team Member"}
                type={"text"}
                placeholder={""}
                fieldData={userData.sales_person_name}
                //setFormData={setUserData}
                //maxLength={50}
                //hasError={furtherError.sales_person_name_error}
                readOnly={true}
                styles={{ textTransform: "capitalize" }}
              />
            </div>

            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"client_person_name"}
                labelName={"Client Team Member"}
                type={"text"}
                placeholder={""}
                fieldData={userData.client_person_name}
                //setFormData={setUserData}
                //maxLength={50}
                //hasError={furtherError.client_person_name_error}
                readOnly={true}
                styles={{ textTransform: "capitalize" }}
              />
            </div>

            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"client_email"}
                labelName={"Client Email"}
                type={"text"}
                placeholder={""}
                fieldData={userData.client_email}
                //setFormData={setUserData}
                //maxLength={50}
                //hasError={furtherError.client_email_error}
                readOnly={true}
              />
            </div>

            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"quotation_time"}
                labelName={"Quotation receive Time"}
                type={"text"}
                placeholder={""}
                fieldData={userData.quotation_time}
                //setFormData={setUserData}
                //maxLength={50}
                //hasError={furtherError.quotation_time_error}
                readOnly={true}
              />
            </div>
          </div>
          <div className="popup-line"></div>
          <div className="flexBox popup">
            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"currency"}
                labelName={"Currency"}
                type={"text"}
                placeholder={""}
                fieldData={userData.currency}
                //setFormData={setUserData}
                //maxLength={50}
                //hasError={furtherError.quotation_time_error}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"currency_value"}
                labelName={"Quoted Value"}
                type={"text"}
                placeholder={""}
                fieldData={userData.currency_value}
                //setFormData={setUserData}
                //maxLength={50}
                //hasError={furtherError.quotation_time_error}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"order_value"}
                labelName={"Order Value"}
                // type={"number"}
                placeholder={""}
                fieldData={userData.order_value}
                setFormData={AwaitingOrder ? setUserData : undefined}
                maxLength={10}
                hasError={
                  AwaitingOrder ? furtherError.order_value_error : undefined
                }
                readOnly={!AwaitingOrder && !updatingFun}
                pattern={"numberWithFloat"}
              />
            </div>

            <div style={{ width: rem(300) }} className="">
              <InputField
                label={"order_date"}
                labelName={"Order Date Time"}
                type={"date"}
                placeholder={""}
                fieldData={userData.order_date}
                setFormData={AwaitingOrder ? setUserData : undefined}
                maxLength={maxDate}
                hasError={AwaitingOrder ? furtherError.order_date : undefined}
                readOnly={!AwaitingOrder && !updatingFun}
                minLength={formatDate(user?.quotation_time)}
              />
            </div>

            <div>
              <span className="df api-btn">
                {/* <Button
                  btnname={deletingFun ? "Deleting..." : "Delete"}
                  onclick={handleDeleteClick}
                  disabled={deletingFun}
                /> */}
                {AwaitingOrder && (
                  <Button
                    onclick={handleOrderPlaced}
                    btnname={updatingFun ? "Updating..." : "Update"}
                    disabled={updatingFun}
                    fs={20}
                    height={41}
                    br={4}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPopup;
