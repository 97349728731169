import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SideBar.scss";
import Home from "./../../Assets/SideBarIcon/Home.svg";
import QuoteGenerator from "./../../Assets/SideBarIcon/Speech bubble.svg";
// import Procurement from "./../../Assets/SideBarIcon/Deal.svg";
import Quality from "./../../Assets/SideBarIcon/Medal.svg";
import Sales from "./../../Assets/SideBarIcon/Sales.svg";
import arrowIcon from "./../../Assets/SideBarIcon/arrowIcon.svg";
import Homeactive from "./../../Assets/SideBarIcon/Homeactive.svg";
import QuoteGeneratoractive from "./../../Assets/SideBarIcon/Speech bubbleactive.svg";
// import Procurementactive from "./../../Assets/SideBarIcon/Dealactive.svg";
import Qualityactive from "./../../Assets/SideBarIcon/MedalActive.svg";
import Salesactive from "./../../Assets/SideBarIcon/Salesactive.svg";
import arrowIconactive from "./../../Assets/SideBarIcon/Arrowactive.svg";
import Useractive from "./../../Assets/SideBarIcon/Useractive.svg";
// import heat from "./../../Assets/SideBarIcon/heat.svg";
// import heatActive from "./../../Assets/SideBarIcon/heatnumberActive.svg";
import feedback from "./../../Assets/SideBarIcon/feedback.svg";
import feedbackActive from "./../../Assets/SideBarIcon/feedactive.svg";
import User from "./../../Assets/SideBarIcon/User.svg";
// import sideBarClose from "../../Assets/SidebarClose.svg";
import {
  selectIsToggleOn,
  selectUserData,
  toggle,
  selectDataCount,
  setdatacount,
} from "../../Redux/Reducers";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { useUserData } from "../../Utils/UserDataProvider";
import { rem } from "../Rem_func";
import profile_pic from "../../Assets/SideBarIcon/Background.svg";
import logout_img from "../../Assets/SideBarIcon/logout.svg";
import logoImg from "../../Assets/NavbarIcon/spira 1.svg";

// Custom hook to handle sessionStorage operations
const useSessionStorage = (key, initialValue) => {
  const storedValue = sessionStorage.getItem(key);
  const initial = storedValue ? new Map(JSON.parse(storedValue)) : initialValue;
  const [value, setValue] = useState(initial);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(Array.from(value.entries())));
  }, [key, value]);

  return [value, setValue];
};

// Function to calculate dropdown height
const calculateHeight = (dropDownDataLength) => {
  return `${dropDownDataLength * 48 + 20}`;
};

const SideBar = () => {
  // State hooks
  const [dropdownState, setDropdownState] = useSessionStorage(
    "dropdownState",
    new Map()
  );
  const [access, setAccess] = useState();
  const { fetchData } = useUserData();

  // Redux hooks
  const dispatch = useDispatch();
  const isSideBarOn = useSelector(selectIsToggleOn);
  const isAccess = useSelector(selectUserData);
  const DataCount = useSelector(selectDataCount);
  // React Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Effect to set access state from sessionStorage
  useEffect(() => {
    setAccess(isAccess);
  }, [isAccess]);

  useEffect(() => {
    if (!access || Object.keys(access).length === 0) {
      fetchData();
    }
  }, [access, fetchData]);

  // Sidebar data
  const sidebarData = [
    {
      text: "Home",
      link: "/SpiraAI/Dashboard",
      imgSrc: Home,
      activeImgSrc: Homeactive,
      alt: "side-img",
      dropdown: false,
      linksactive: true,
      permission: true,
      arrowImage: false,
    },

    {
      text: "Sales Tracker",
      link: "#",
      activeImgSrc: Salesactive,
      imgSrc: Sales,
      alt: "side-img",
      dropdown: true,
      permission: access?.sales_tracker,
      arrowImage: true,
      dropDownData: [
        { title: "Dashboard", link: "/Salestracker/Dashboard" },
        { title: "All Enquiries", link: "/enquires", count: true },
        { title: "Awaiting Quote", link: "/awaiting-quotes", count: true },
        { title: "Awaiting Order", link: "/awaiting-order", count: true },
        { title: "Order Placed", link: "/order-placed", count: true },
        { title: "Report", link: "/Salestracker/report" },
      ],
      calculatedHeight: calculateHeight(6),
    },
    {
      text: "Quality",
      link: "/QualityTeam.MTCgenerator",
      activeImgSrc: Qualityactive,
      imgSrc: Quality,
      alt: "side-img",
      permission: access?.quality,
      linksactive: true,
      arrowImage: false,
    },
    {
      text: "Quote Generator",
      link: "/QuoteGenerator",
      activeImgSrc: QuoteGeneratoractive,
      imgSrc: QuoteGenerator,
      alt: "side-img",
      permission: access?.quote_generator,
      dropdown: false,
      arrowImage: false,
    },
    {
      text: "Feedback",
      link: "#",
      imgSrc: feedback,
      activeImgSrc: feedbackActive,
      alt: "side-img",
      dropdown: true,
      linksactive: true,
      permission: access?.feedback_form,
      arrowImage: true,
      dropDownData: [
        { title: "Feedback Form", link: "/feedbackform" },
        { title: "Feedback Stats", link: "/feedbackdata", count: true },
      ],
      calculatedHeight: calculateHeight(2),
    },
  ];

  // Function to handle dropdown toggle
  const handleDropDown = (index) => {
    const newDropdownState = new Map(dropdownState);
    // to toggle dropdown when click on other dropdown
    // for (const [i, isOpen] of newDropdownState.entries()) {
    //   if (i !== index && isOpen) {
    //     newDropdownState.set(i, false);
    //   }
    // }

    newDropdownState.set(index, !newDropdownState.get(index));
    // while closing sidebar set dropdowns to hide
    if (!isSideBarOn) {
      for (const [i] of newDropdownState.entries()) {
        newDropdownState.set(i, false);
      }
    }

    setDropdownState(newDropdownState);

    const item = sidebarData[index];
    if (item.link && !item.dropdown) {
      navigate(item.link);
    }

    sessionStorage.setItem(
      "dropdownState",
      JSON.stringify(Array.from(newDropdownState.entries()))
    );
    // to handle dropdown when sidebar is minimised
    if (item.dropdown && !isSideBarOn) {
      dispatch(toggle());
      for (const [i] of newDropdownState.entries()) {
        if (i === index) {
          newDropdownState.set(i, true);
        }
      }
    }
  };

  // Function to handle sidebar toggle
  // const handleSideBar = () => {
  //   const newDropdownState = new Map(dropdownState);
  //   // Toggle the sidebar state
  //   dispatch(toggle());
  //   setDropdownState(new Map(newDropdownState)); // Ensure to create a new Map instance
  // };

  // Function to handle link click
  const handleLinkClick = (event, link) => {
    dispatch(setdatacount(""));
    navigate(link);
    event.stopPropagation();
  };
  // Function to check if dropdown is active
  const isActiveDropdown = (index) =>
    dropdownState.get(index) && sidebarData[index].dropdown;

  // Filter permitted items based on user access
  const permittedItems = sidebarData?.filter((item) => item.permission);

  // Map sidebar elements
  const sidebarElements = permittedItems?.map((item, index) => (
    <li onClick={() => handleDropDown(index)} key={index}>
      <div
        className={`sidebar-link df Color${
          location.pathname === item.link ||
          (item.dropDownData &&
            item.dropDownData.some(
              (dropItem) => location.pathname === dropItem.link
            ))
            ? "1"
            : ""
        }`}
        // data-tooltip-id="tooltip-arrow"
        data-tooltip-content={isSideBarOn ? "" : item.text}
      >
        <div className="df sidebar-link-right">
          <img
            src={
              location.pathname === item.link ||
              (item.dropDownData &&
                item.dropDownData.some(
                  (dropItem) => location.pathname === dropItem.link
                ))
                ? item.activeImgSrc
                : item.imgSrc
            }
            alt={item.alt}
            className={`${
              dropdownState[index] && item.dropdown ? "Border-Color" : ""
            }`}
          />
          <p
            className={`${
              location.pathname === item.link ||
              (item.dropDownData &&
                item.dropDownData.some(
                  (dropItem) => location.pathname === dropItem.link
                ))
                ? "Border-Color"
                : "sidebar-link-title"
            }`}
          >
            {item.text}
          </p>
        </div>
        {item.arrowImage && (
          <div
            className={`homesidebar-rightarrow ${
              isActiveDropdown(index) ? "arrow-down" : ""
            }`}
          >
            <img
              src={
                item.dropDownData &&
                item.dropDownData.some(
                  (dropItem) => location.pathname === dropItem.link
                )
                  ? arrowIconactive
                  : arrowIcon
              }
              alt="icon"
            />
          </div>
        )}
      </div>
      {/* // Mapping dropdown elements */}

      {
        <div
          className={`dropdown-line df ${
            isActiveDropdown(index) ? "active" : ""
          }`}
          style={
            isSideBarOn && isActiveDropdown(index)
              ? { height: rem(item.calculatedHeight) }
              : { padding: "0" }
          }
        >
          <div className={` dropdown-link df`}>
            <ul className={`df  `}>
              {(item.dropDownData || []).map((dropItem, dropIndex) => (
                <div
                  className={`sidebar-dropdown ${
                    location.pathname === dropItem.link ? "activelink" : ""
                  }`}
                  key={dropIndex}
                >
                  <li
                    onClick={(event) => handleLinkClick(event, dropItem.link)}
                    className={`df ${
                      location.pathname === dropItem.link
                        ? "link-col"
                        : "link-col1"
                    } dropdown-links`}
                  >
                    {location.pathname === dropItem.link && dropItem?.count && (
                      <div
                        className="point df"
                        style={{ display: DataCount >= 0 ? "" : "none" }}
                      >
                        {DataCount ? DataCount : 0}
                      </div>
                    )}
                    {dropItem.title}
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </div>
      }
    </li>
  ));

  const skeletonElements = (
    <div>
      {Array(6) // Creates an array with 6 empty slots
        .fill(null) // Fills the array with 'null' values
        .map(
          (
            _,
            index // Iterates over each slot
          ) => (
            <div key={index} className="skeleton df align-center"></div>
          )
        )}
    </div>
  );

  // Rendering the Sidebar component

  const handleUserNavigate = () => {
    navigate("/user");
  };

  const handleuserprofile = () => {
    navigate("/SpiraAI/user-profile");
  };
  return (
    <aside className="sidebar" style={{ position: "relative" }}>
      <div className="logo_img">
        <img src={logoImg} alt="error" />
      </div>
      <span className="sidebar_line">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={rem(240)}
          height={rem(6)}
          viewBox="0 0 240 6"
          fill="none"
        >
          <path
            d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM234.333 3C234.333 4.47276 235.527 5.66667 237 5.66667C238.473 5.66667 239.667 4.47276 239.667 3C239.667 1.52724 238.473 0.333333 237 0.333333C235.527 0.333333 234.333 1.52724 234.333 3ZM3 3.5H237V2.5H3V3.5Z"
            fill="#E9E9E9"
          />
        </svg>
      </span>
      <div className="sidebar-container">
        <ul className={`home-sidebar`}>
          {isAccess ? <>{sidebarElements}</> : skeletonElements}
        </ul>

        {/* profile section */}
        <div className="bottom_main home-sidebar">
          {access?.user_management && (
            <div
              className={`sidebar-link df Color${
                location.pathname === "/user" ? "1" : ""
              }`}
              onClick={handleUserNavigate}
            >
              <div className="df sidebar-link-right">
                <img
                  src={location.pathname === "/user" ? Useractive : User}
                  alt="error"
                />
                <p
                  className={
                    location.pathname === "/user"
                      ? "Border-Color"
                      : "sidebar-link-title"
                  }
                >
                  User Management
                </p>
              </div>
            </div>
          )}
          <div
            className="profile_section"
            onClick={handleuserprofile}
            style={{ cursor: "pointer" }}
          >
            <div className="left-profile df">
              <img src={profile_pic} alt="error" />
              <div className="profile">
                <p className="profile_name">{access?.name}</p>
                <p className="profile_email">{access?.user_email}</p>
              </div>
            </div>
            <div className="right-profile">
              <img src={logout_img} alt="error" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className={``} onClick={handleSideBar}>
        <img
          className={`toggle-sidebar-icon ${
            isSideBarOn ? "close-sidebar" : "open-sidebar"
          }`}
          src={sideBarClose}
          alt="icon"
          data-tooltip-id="tooltip-arrow"
          data-tooltip-content={isSideBarOn ? "Close Sidebar" : "Open Sidebar"}
        />
      </div> */}
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{
          fontSize: rem(16),
          padding: rem(7),
          zIndex: "2",
          borderRadius: rem(5),
        }}
      />
    </aside>
  );
};

export default SideBar;
