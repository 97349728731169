import React, { useState } from "react";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { rem } from "../../../Components/Rem_func";

const SalesHeader = () => {
  const option = ["All", "AbuDhabi", "Dubai", "Oman"];
  const [location, setLocation] = useState({ city: "All" });
  const handleSelectChange = (name, value) => {
    setLocation((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="sales-header df">
      <div className="sales-date df">
        <span className="sales-label">Date Range</span>
        <input type="date" name="" id="" />
        <input type="date" name="" id="" />
      </div>
      <div className="sales-year df">
        <span className="sales-label">Year</span>
        <button>2019</button>
        <button>2020</button>
        <button>2021</button>
        <button>2022</button>
      </div>
      <div className="sales-year df">
        <span className="sales-label">Quater</span>
        <button>Qtr-1</button>
        <button>Qtr-2</button>
        <button>Qtr-3</button>
        <button>Qtr-4</button>
      </div>
      {/* <div className="sales-location df">
        <span className="sales-label">Location</span>
        <div style={{ width: rem(128) }}>
          <SelectSearch
            options={option}
            // label="Reporting to"
            name="city"
            Placeholder="All"
            value={location.city}
            onChange={(value) => handleSelectChange("city", value)}
            height={36}
            // hasError={furtherError.city_error}
          />
        </div>
      </div> */}
    </div>
  );
};

export default SalesHeader;
