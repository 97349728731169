import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import InputField from "../../Components/InputField/InputField";
import SelectSearch from "../../Components/SelectFields/SelectField";
import { rem } from "../../Components/Rem_func";
const FilterFeedback = ({ onFilter, hide }) => {
  // // State variables to store filter criteria
  const [filteredData, setFilteredData] = useState({
    form_date: "",
    company_name: "",
    client_name: "",
    client_disignation: "",
    telephone_number: "",
    email_address: "",
    other_feedback: "",
    quality_rate: "",
    services_experience_rate: "",
    technical_enquires_rate: "",
    team_communication_rate: "",
    team_help_rate: "",
    product_quality_punctuality_rate: "",
    customer_statisfaction_rate: "",
    service_provider_rate: "",
    about_team_product_service: "",
  });

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();

  //   quality rate options
  const checkOptions = ["very Good", "good", "average", "poor"];

  // Function to handle filter application
  const handleFilter = () => {
    onFilter(filteredData);
    hide();
  };

  // Function to reset filters
  const ResetFilter = () => {
    const resetData = {
      form_date: "",
      company_name: "",
      client_name: "",
      client_disignation: "",
      telephone_number: "",
      email_address: "",
      other_feedback: "",
      quality_rate: "",
      services_experience_rate: "",
      technical_enquires_rate: "",
      team_communication_rate: "",
      team_help_rate: "",
      product_quality_punctuality_rate: "",
      customer_statisfaction_rate: "",
      service_provider_rate: "",
      about_team_product_service: "",
    };

    setFilteredData(resetData);
    onFilter(resetData);
  };
  const handleSelectChange = (name, value) => {
    setFilteredData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>
      <div className="filter-body">
        <InputField
          label={"form_date"}
          labelName={"Date"}
          type={"date"}
          placeholder={""}
          fieldData={filteredData.form_date}
          setFormData={setFilteredData}
          maxLength={maxDate}
        />

        <InputField
          label={"company_name"}
          labelName={"Company Name"}
          type={"text"}
          placeholder={""}
          fieldData={filteredData.company_name}
          setFormData={setFilteredData}
          maxLength={50}
        />
        <InputField
          label={"client_name"}
          labelName={"Client name"}
          type={"text"}
          placeholder={""}
          fieldData={filteredData.client_name}
          setFormData={setFilteredData}
          maxLength={50}
          pattern={"onlyAlpha"}
        />
        <InputField
          label={"client_disignation"}
          labelName={"Client Designation"}
          type={"text"}
          placeholder={""}
          fieldData={filteredData.client_disignation}
          setFormData={setFilteredData}
          maxLength={50}
          pattern={""}
        />
        <InputField
          label={"telephone_number"}
          labelName={"TelePhone Number"}
          type={"number"}
          placeholder={"+91"}
          fieldData={filteredData.telephone_number}
          setFormData={setFilteredData}
          maxLength={10}
          pattern={"mobile"}
        />
        <InputField
          label={"email_address"}
          labelName={"Email Address"}
          type={"text"}
          placeholder={"enter your email"}
          fieldData={filteredData.email_address}
          setFormData={setFilteredData}
          maxLength={50}
          pattern={"email"}
        />

        <div>
          <SelectSearch
            label="Quality rate"
            name="quality_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={filteredData?.quality_rate}
            onChange={(value) => handleSelectChange("quality_rate", value)}
            height={36}
          />
        </div>
        <div>
          <SelectSearch
            label="Services Experience Rate"
            name="services_experience_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={filteredData?.services_experience_rate}
            onChange={(value) =>
              handleSelectChange("services_experience_rate", value)
            }
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Technical enquires rate"
            name="technical_enquires_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={filteredData?.technical_enquires_rate}
            onChange={(value) =>
              handleSelectChange("technical_enquires_rate", value)
            }
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Team Communication Rate"
            name="team_communication_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={filteredData?.team_communication_rate}
            onChange={(value) =>
              handleSelectChange("team_communication_rate", value)
            }
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Team Help Rate"
            name="team_help_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={filteredData?.team_help_rate}
            onChange={(value) => handleSelectChange("team_help_rate", value)}
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Product Quality Punctuality Rate"
            name="product_quality_punctuality_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={filteredData?.product_quality_punctuality_rate}
            onChange={(value) =>
              handleSelectChange("product_quality_punctuality_rate", value)
            }
            height={36}
          />
        </div>

        <div>
          <SelectSearch
            label="Customer Satisfaction Rate"
            name="customer_statisfaction_rate"
            Placeholder="Select option"
            options={checkOptions}
            value={filteredData?.customer_statisfaction_rate}
            onChange={(value) =>
              handleSelectChange("customer_statisfaction_rate", value)
            }
            height={36}
          />
        </div>
      </div>

      <div className="filter-btn">
        <div>
          <Button
            btnname={"Reset"}
            bg={"#fff"}
            cl={"#000"}
            border={`${rem(1)} solid #1E6641`}
            onclick={ResetFilter}
            box_s={"none"}
          />
        </div>
        <div>
          <Button
            btnname={"Apply Now"}
            onclick={handleFilter}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleFilter();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterFeedback;
