import React from "react";
import gasLabel from "./../../Assets/Quality/image4.svg";
import QualityTable from "./QualityTable";

const QualityPopUp = () => {
  return (
    <div className="popping_container">
      <div className="upper_half">
        <div className="leftImg">
          <img src={gasLabel} alt="spira logo" />
        </div>
        <div className="rightCont">
          P.O. Box: 390844, W- 30 St. 22 Al Quoz Industrial Area No.3 Dubai,
          United Arab Emirates Tel# +971 4 3474688 Fax# +971 4 3474689 E-mail:
          info@spirapower.com WEB: www.spirapower.com
        </div>
      </div>
      <p className="ppt">
        Inspection Certificate to BS EN 10204-3.1(DIN 50049-3.1)
      </p>
      <QualityTable />
    </div>
  );
};

export default QualityPopUp;
