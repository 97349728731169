import React, { useState } from "react";
import _nonAuthHttp from "../../../Utils/Api/_nonAuthHttp";
import { useNavigate } from "react-router-dom";
import spiraLogo from "./../../../Assets/LoginPage/spira 1.svg";
import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import SnackBar from "../../../Components/SnackBar";
import "../Login/Login.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login() {
  // State variables for managing form data, SnackBar visibility, navigation, loading state, and errors
  const [formData, setFormData] = useState({
    spirausername: "",
    spirapassword: "",
    spiraemail: "",
    role_name: "",
    createdDate: `${new Date().toISOString()}`,
    reporting_to: "",
  });
  const [open, setOpen] = useState({ submit: false, error: false });
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePasswordStrength = (password) => {
    const errors = [];
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    // const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      errors.push(`${minLength} characters long`);
    }
    if (!hasUpperCase) {
      errors.push("one uppercase letter");
    }
    if (!hasNumber) {
      errors.push("one number");
    }
    if (!hasSpecialChar) {
      errors.push("one special character");
    }
    if (errors.length > 0) {
      return `Password must contain ${errors.join(", ")}`;
    }
    return null;
  };

  // Event handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the regex patterns based on input type
    let pattern;
    if (name === "spirausername") {
      pattern = /^[A-Za-z\s]*$/;
    } else if (name === "spiraemail") {
      pattern = /^[a-z0-9.@]*$/;
    } else if (name === "spirapassword") {
      pattern = /^[^\s]+$/;
    }

    // Allow empty value to let the user delete input
    if (!pattern || value === "" || pattern.test(value)) {
      if (name === "spiraemail") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
            .replace(/\s/g, "")
            .replace(/[^a-zA-Z0-9.@ ]/g, "")
            .replace(/([,.@])\1+/g, "$1"),
        }));
        return;
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.replace(/\s+/g, " "),
      }));
    }
  };

  // Function to close the SnackBar
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };

  // Function to validate registration input
  const validateLoginInput = (email, spirausername, password) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (
      spirausername?.length === 0 ||
      password?.length === 0 ||
      email?.length === 0
    ) {
      return "Please fill out all fields";
    }
    if (!emailPattern?.test(email) || /^\d+$/.test(email.split("@")[0])) {
      return "Please enter a valid email address";
    }
    return null;
  };

  // Function to handle the registration process
  const handleRegister = async (e) => {
    e.preventDefault();

    // Validate registration input
    const inputError = validateLoginInput(
      formData.spiraemail,
      formData.spirausername,
      formData.spirapassword
    );

    if (inputError) {
      setOpen({ error: true });
      setErrors(inputError);
      return;
    }

    // Validate password strength
    const passwordErrors = validatePasswordStrength(formData.spirapassword);
    if (passwordErrors) {
      setOpen({ error: true });
      setErrors(passwordErrors);
      return;
    }

    setloading(true);

    try {
      // console.log(formData);
      // return;

      const arr = {
        email: formData.spiraemail.toLowerCase(),
        username: formData.spirausername.trim().toLowerCase(),
        password: formData.spirapassword.trim(),
        createdDate: formData.createdDate,
        role_name: formData.role_name,
        reporting_to: formData.reporting_to,
      };
      // Make a registration request using _nonAuthHttp
      const res = await _nonAuthHttp.post("/Register", arr);
      if (res.status === 201) {
        // Handle successful registration
        setOpen({ submit: true });
        setErrors("Successfully registered! Please login.");
        setFormData({
          spirausername: "",
          spirapassword: "",
          spiraemail: "",
        });
        setTimeout(() => {
          navigate("/Login");
        }, [3000]);
      } else {
        // Handle registration error
        setOpen({ error: true });
        setErrors(res.data);
      }
    } catch (error) {
      // Handle registration error exceptions
      setOpen({ error: true });
      if (error.response && error.response.status === 500) {
        setErrors("Internal server error ");
      } else if (error.response && error.response.status === 400) {
        setErrors("User already exists");
      } else {
        setErrors(error.message);
        console.error("Error during registration:", error);
      }
    } finally {
      setloading(false);
    }
  };

  // JSX for the registration component
  return (
    <div className="newLogin flexBox signup">
      {/* <div className="left flexBox">
        <img src={LoginImage} alt="CompanyLogo"></img>
      </div> */}
      <div className="login-logo login-title">
        <img src={spiraLogo} alt="img" />
      </div>
      <div className={`right flexBox`}>
        <div className={`login-card`}>
          <form className="login-card-form">
            {/* Input fields for email, spirausername, and password */}
            <h1>Join the Power Revolution: Register with Spira Power today!</h1>
            <label htmlFor="email">Spira Email ID</label>
            <input
              // type="text"
              name="spiraemail"
              id="email"
              placeholder="johndoe@spirapower.com"
              value={formData.spiraemail}
              onChange={handleChange}
              className="txtBox"
              readOnly={loading}
              autoComplete="off"
            />

            <label htmlFor="spirausername">User Name</label>
            <input
              // type="text"
              name="spirausername"
              id="username"
              placeholder="John Doe"
              value={formData.spirausername}
              onChange={handleChange}
              className="txtBox"
              readOnly={loading}
              autoComplete="off"
            />

            <label htmlFor="password">Password</label>

            <div className="password-input txtBox">
              <input
                type={showPassword ? "text" : "password"}
                name="spirapassword"
                id="password"
                placeholder="********"
                onChange={handleChange}
                className=""
                value={formData.spirapassword}
                readOnly={loading}
                autoComplete="off"
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {/* SnackBar components for displaying errors and success messages */}
            <SnackBar
              message={errors}
              severity={"error"}
              handleClose={handleClose}
              Open={open.error}
            />
            <SnackBar
              message={errors}
              severity={"success"}
              handleClose={handleClose}
              Open={open.submit}
            />

            <div className="login-btn">
              {/* Button component for registering, with loading state handling */}
              <Button
                disabled={loading}
                btnname={loading ? "Activating Account..." : "Activate Account"}
                onclick={handleRegister}
                width={"100%"}
                height={57}
                fs={20}
                fw={600}
                br={4}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleRegister();
                  }
                }}
              />
              {/* Link to navigate to the login page */}
              <div className="login-title dont-have-acc ">
                <span> Already activated?</span>

                <Link className="subtitle" to="/Login">
                  Login to Spira Power AI
                </Link>
              </div>
            </div>
          </form>
        </div>
        <div className="w100 foot">
          <div className="df footer">
            <p className="footer-color">
              &copy; 2024 Spira Power - All Rights Reserved.
            </p>
            <div className="df footer1">
              <Link to="#" className="footer-color">
                Privacy Policy
              </Link>
              <Link to="#" className="footer-color">
                Cookies
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
