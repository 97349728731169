// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #fcb02a;
  --primary-color-rgb: 252, 176, 42;
  --secondary-color: #757575;
  --secondary-color-rgb: 112, 112, 112;
  --body_font_color: #262626;
  --machine-alert: var(--primary-color);
  --machine-warning: #ff2600;
  --machine-success: #27c24c;
  --default-border: #dfdfdf;
  --default-border-rgb: 208, 208, 208;
  --body-bg: #f2f3f7;
  --static-white: #fff;
}

.spira-ui-btn {
  display: inline-flex;
  height: 2rem;
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #1e6641;
  border: none;
  color: #fff;
  font-family: "Outfit";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}`, "",{"version":3,"sources":["webpack://./src/Sass/Variable.scss","webpack://./src/Components/Button/Button.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,iCAAA;EACA,0BAAA;EACA,oCAAA;EACA,0BAAA;EACA,qCAAA;EACA,0BAAA;EACA,0BAAA;EACA,yBAAA;EACA,mCAAA;EACA,kBAAA;EACA,oBAAA;ACCF;;AAXA;EACE,oBAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,cAAA;EACA,qBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;AAcF","sourcesContent":[":root {\n  --primary-color: #fcb02a;\n  --primary-color-rgb: 252, 176, 42;\n  --secondary-color: #757575;\n  --secondary-color-rgb: 112, 112, 112;\n  --body_font_color: #262626;\n  --machine-alert: var(--primary-color);\n  --machine-warning: #ff2600;\n  --machine-success: #27c24c;\n  --default-border: #dfdfdf;\n  --default-border-rgb: 208, 208, 208;\n  --body-bg: #f2f3f7;\n  --static-white: #fff;\n}\n\n// REM Calculations\n$baseFontSize: 16px;\n@function rem($pixels, $context: $baseFontSize) {\n  @if (unitless($pixels)) {\n    $pixels: $pixels * 1px;\n  }\n  @if (unitless($context)) {\n    $context: $context * 1px;\n  }\n  @return $pixels / $context * 1rem;\n}\n\n$box-border: rem(1) solid #e0e7ed;\n","@import \"../../Sass/Variable.scss\";\n\n.spira-ui-btn {\n  display: inline-flex;\n  height: rem(32);\n  padding: rem(8) rem(16);\n  align-items: center;\n  justify-content: center;\n  gap: rem(8);\n  flex-shrink: 0;\n  border-radius: rem(8);\n  background: #1e6641;\n  border: none;\n  color: #fff;\n  font-family: \"Outfit\";\n  font-size: rem(14);\n  font-style: normal;\n  font-weight: 500;\n  line-height: rem(20);\n  // letter-spacing: rem(0.28);\n  // box-shadow: 0px 1px 0px 0px #1e6641 inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.14),\n  //   0px 0px 0px 1px #1e6641, 0px 2px 5px 0px rgba(30, 102, 65, 0.12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
