import React from "react";
import UnderConstruction from "../../../Components/UnderConstruction";

const Report = () => {
  return (
    <section>
      <UnderConstruction />
    </section>
  );
};

export default Report;
