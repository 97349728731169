import React from "react";

const SalesRange = () => {
  return (
    <div className="sales-range df">
      <div className="range-box flexBox">
        <div className="box df">
          <span className="label">Quoted (AED)</span>
          <span className="value">147 M</span>
        </div>
        <div className="box df">
          <span className="label">Awarded (AED)</span>
          <span className="value">57 M</span>
        </div>
        <div className="box df">
          <span className="label">Gross Profit</span>
          <span className="value">15 M</span>
        </div>
        <div className="box df">
          <span className="label"># of inquiries</span>
          <span className="value">5862</span>
        </div>
      </div>
      <div className="range-bar">
        <div className="progress-label">Conversation %</div>

        <div className="semi-circular-progress">
          <svg viewBox="0 0 36 18" className="semi-circular-chart">
            <path
              className="semi-circle-bg"
              d="M18 18
               m -16 0
               a 16 16 0 0 1 32 0"
              stroke="#E0E7ED"
              strokeWidth="3"
              strokeLinecap="round" /* Ensures the corners are rounded */
              //   strokeDasharray="49 49"
            />
            <path
              className="semi-circle"
              strokeDasharray={`${(56 / 100) * 50} 50`}
              d="M18 18
               m -16 0
               a 16 16 0 0 1 32 0"
              stroke="#1E6641"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <text x="18" y="12" className="percentage">{`${56}%`}</text>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SalesRange;
