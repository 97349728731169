import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import Login from "./Pages/Login-Register/Login/Login";
import Register from "./Pages/Login-Register/Register/Register";
import { ProtectedRoutes } from "./Utils/Auth/ProtectedRoutes";
import Home from "./Pages/Home/Home";
import dynamicRoutes from "./RoutingData/RoutingData";
import PageNotFound from "./Components/PageNotFound";
import NotAccess from "./Components/NotAccess";
import GlobalStyle from "./Components/GlobaleStyle";
import { useSelector } from "react-redux";
import { selectUserData } from "./Redux/Reducers";
import FeedbackForm from "./Pages/Feedback/FeedbackForm/FeedbackForm";
import FileViewers from "./Pages/Quality/FileViewer";
import ForgetPassword from "./Pages/Forget-Reset-Password/ForgetPassword";
import UserProfile from "./Pages/UserProfile/UserProfile";

const App = () => {
  const userdata = useSelector(selectUserData);
  // State variable to store access data
  const access = userdata;
  const currentWidth = window.innerWidth;
  const currentHeight = window.innerHeight;
  console.log(currentWidth, currentHeight);

  // Check if user agent includes 'Windows' to conditionally apply global styles
  // const isWindows = navigator.userAgent.includes("Windows");

  // Generating dynamic routes based on access data
  const generateDynamicRoutes = useMemo(() => {
    return dynamicRoutes.map(({ path, prop, component }) => {
      const routePath = path;
      const isAccessAllowed = access?.[prop];
      return (
        <Route
          key={path}
          path={routePath}
          element={isAccessAllowed ? component : <NotAccess />}
        />
      );
    });
  }, [access]);

  return (
    <>
      {<GlobalStyle />}
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/Login" />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/generate-newpassword" element={<ForgetPassword />} />
          <Route path="/NotAccess" element={<NotAccess />} />
          <Route path="feedbackform" element={<FeedbackForm />} />
          <Route path="/fileviewer" element={<FileViewers />} />
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="SpiraAI/Dashboard" element={<Home />} />
            <Route path="SpiraAI/user-profile" element={<UserProfile />} />
            {generateDynamicRoutes}
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
