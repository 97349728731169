import React from "react";
import "./Home.scss";
import UserHome from "./UserHome/UserHome";
import QualityHome from "./QualityHome/QualityHome";
import QuoteHome from "./QuoteHome/QuoteHome";
import SalesHome from "./SalesHome/SalesHome";
import FeedbackStats from "./FeedbackStats/FeedbackStats";
function Home() {
  return (
    <section className="home_container flexColumn df">
      <div className="df box">
        <QuoteHome />
        <QualityHome />
        <UserHome />
      </div>
      <div className="df box">
        <SalesHome />
        <FeedbackStats />
      </div>
    </section>
  );
}

export default Home;
