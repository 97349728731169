import React, { useState, useEffect, useCallback } from "react";
import "./UserPopup.scss";
import "../../SalesTracker/STcomponents/STeditPopup/STeditPopup.scss";
import { Modal } from "antd";
import Button from "../../../Components/Button/Button";
import InputField from "../../../Components/InputField/InputField";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { rem } from "../../../Components/Rem_func";

const UserActions = ({
  open,
  handleClose,
  users,
  handleDelete,
  handleUpdate,
  roles,
  userList,
  names,
  handleSaveToast,
  deletingFun,
  updatingFun,
  saveBtn,
  Submit,
}) => {
  const [formData, setFormData] = useState({
    user: users?.user,
    email: users?.email,
    role_name: users.role_name,
    reporting_to: users.reporting_to,
    sales_tracker: users.sales_tracker || false,
    user_management: users.user_management || false,
    quality: users.quality || false,
    feedback_form: users.feedback_form || false,
    quote_generator: users.quote_generator || false,
    password: "",
  });

  const [furtherError, setFurtherErrors] = useState({});

  const permissions = [
    "sales_tracker",
    "user_management",
    "quality",
    "feedback_form",
    "quote_generator",
  ];

  const [reportingTo, setReportingTo] = useState([]);
  const roleTypes = useCallback(() => {
    if (roles === "admin") return ["Manager", "Teamlead", "employee"];
    if (roles === "Manager") return ["Teamlead", "employee"];
    if (roles === "Teamlead") return ["employee"];
    return [];
  }, [roles]);

  const getReportingToOptions = useCallback(
    (roleName) => {
      let reportingToOptions = [];

      if (roleName === "Manager") {
        reportingToOptions = [names];
      } else if (roleName === "Teamlead") {
        const managers = userList
          .filter((user) => user.role_name === "Manager")
          .map((user) => user.user);
        reportingToOptions = managers.length > 0 ? managers : [names];
      } else if (roleName === "employee") {
        const teamleads = userList
          .filter((user) => user.role_name === "Teamlead")
          .map((user) => user.user);
        reportingToOptions = teamleads.length > 0 ? teamleads : [names];
      }

      if (formData.role_name) {
        reportingToOptions = reportingToOptions.filter(
          (option) => option !== formData.user
        );
      }

      setReportingTo(reportingToOptions);
    },
    [names, userList, formData.role_name, formData.user]
  );

  useEffect(() => {
    roleTypes();
    getReportingToOptions(formData.role_name);
  }, [roleTypes, getReportingToOptions, formData.role_name]);

  useEffect(() => {
    if (open) {
      const firstFocusableElement = document.querySelector(".user-popup input");
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [open]);
  const handleDeleteClick = () => {
    handleDelete(formData.user, formData.email);
  };

  const handleUpdateClick = () => {
    const requiredFields = ["user", "email", "role_name", "reporting_to"];
    const emptyFields = [];

    setFurtherErrors((prevData) => {
      const newData = { ...prevData };
      requiredFields.forEach((field) => {
        if (!formData[field] || formData[field].toString().trim() === "") {
          newData[`${field}_error`] = true;
          emptyFields.push(field);
        } else {
          newData[`${field}_error`] = false;
        }
      });
      return newData;
    });

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });
    if (emptyFields.length > 0) {
      console.log("All fields are required");
      handleUpdate(false);
      return;
    }

    const updatedData = { email: formData.email };
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== users[key]) {
        updatedData[key] = formData[key];
      }
    });

    if (Object.keys(updatedData).length === 1) {
      console.log("No changes made");
      return;
    }

    handleUpdate({ email: users.email, data: updatedData });
  };

  const handlePermissionChange = (type) => {
    setFormData((prevData) => ({ ...prevData, [type]: !prevData[type] }));
  };
  const handleSelectChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      // Only clear reporting_to if role_name is changed and it needs to be reset
      ...(name === "role_name" && {
        reporting_to: formData.role_name !== value ? "" : prevData.reporting_to,
      }),
    }));
  };

  // funtion to save the data in add new user

  const validatePasswordStrength = (password) => {
    const errors = [];
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    // const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password?.length < minLength) {
      errors.push(`${minLength} characters long`);
    }
    if (!hasUpperCase) {
      errors.push("one uppercase letter");
    }
    if (!hasNumber) {
      errors.push("one number");
    }
    if (!hasSpecialChar) {
      errors.push("one special character");
    }
    if (errors.length > 0) {
      return `Password must contain ${errors.join(", ")}`;
    }
    return null;
  };

  const handleSubmit = async (e) => {
    const requiredFields = [
      "user",
      "email",
      "role_name",
      "reporting_to",
      "password",
    ];

    const emptyFields = [];

    setFurtherErrors((prevData) => {
      const newData = { ...prevData };
      requiredFields.forEach((field) => {
        if (!formData[field] || formData[field].toString().trim() === "") {
          newData[`${field}_error`] = true;
          emptyFields.push(field);
        } else {
          newData[`${field}_error`] = false;
        }
      });
      return newData;
    });

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });

    const passwordErrors = validatePasswordStrength(formData.password);

    // Validation checks for required fields and email format
    if (emptyFields?.length > 0) {
      handleSaveToast(false, true, "Please fill all the input fields");
      return;
    } else if (
      !/^([a-zA-Z0-9_.]+@[a-zA-Z0-9_]+\.[a-zA-Z]{2,})$/.test(formData.email) ||
      /^\d+$/.test(formData.email.split("@")[0]) // Check if the part before '@' is only numeric
    ) {
      handleSaveToast(false, true, "Wrong email format");
      return;
    } else if (passwordErrors) {
      handleSaveToast(false, true, passwordErrors);
      return;
    } else {
      Submit(formData, setFormData);
    }
  };

  return (
    <Modal
      centered
      open={open}
      onCancel={handleClose}
      width={rem(808)}
      // height={rem(400)}
      className="edit-popup"
    >
      <div className="user-popup ">
        <div className="flexBox popup">
          <div style={{ width: rem(300) }}>
            <InputField
              label={"user"}
              labelName={"Full Name"}
              type={"text"}
              placeholder={"Full Name"}
              fieldData={formData?.user}
              setFormData={setFormData}
              maxLength={50}
              hasError={furtherError.user_error}
              height={rem(36)}
              readOnly={users.user ? users.user : saveBtn}
              pattern={"onlyAlpha"}
              // styles={{ textTransform: "capitalize" }}
            />
          </div>

          <div style={{ width: rem(300) }} className="">
            <InputField
              label={"email"}
              labelName={"Email"}
              type={"text"}
              placeholder={"Spira Email"}
              fieldData={formData?.email}
              setFormData={setFormData}
              maxLength={50}
              hasError={furtherError.email_error}
              height={rem(36)}
              readOnly={users.user ? users.user : saveBtn}
              pattern={"email"}
            />
          </div>

          <div style={{ width: rem(300) }} className="">
            <SelectSearch
              label="User Role"
              name="role_name"
              Placeholder="Select option"
              options={roleTypes()}
              value={formData?.role_name}
              onChange={(value) => handleSelectChange("role_name", value)}
              hasError={furtherError.role_name_error}
              height={36}
              readOnly={users.user ? updatingFun : saveBtn}
              dropdownPositionProp={"top"}
            />
          </div>

          <div style={{ width: rem(300) }} className="">
            <SelectSearch
              label="Reporting To"
              name="reporting_to"
              Placeholder="Select option"
              options={reportingTo}
              value={formData?.reporting_to}
              onChange={(value) => handleSelectChange("reporting_to", value)}
              hasError={furtherError.reporting_to_error}
              height={36}
              readOnly={users.user ? updatingFun : saveBtn}
              dropdownPositionProp={"top"}
            />
          </div>
        </div>
        <div className="popup-line" />
        <div className="flexBox popup">
          {!users && (
            <>
              <div style={{ width: rem(300) }} className="">
                <InputField
                  label={"password"}
                  labelName={"Password"}
                  type={"password"}
                  placeholder={"********"}
                  fieldData={formData?.password}
                  setFormData={setFormData}
                  maxLength={50}
                  hasError={furtherError.password_error}
                  height={rem(36)}
                  pattern={"password"}
                  readOnly={saveBtn}
                  // validatePassword={validatePasswordStrength}
                />
              </div>

              <div>
                <Button
                  btnname={saveBtn ? "Saving..." : "Save"}
                  disabled={saveBtn}
                  onclick={handleSubmit}
                  width={"112px"}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  fs={20}
                  height={41}
                  br={4}
                />
              </div>
            </>
          )}

          {users && (
            <>
              <div className="">
                <div className="sub-head">Permissions</div>
                <div className="permission-box">
                  {permissions.map((data, i) => (
                    <div key={i} className="permission">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          checked={formData[data] || false}
                          onChange={() => handlePermissionChange(data)}
                          readOnly={updatingFun}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label>{data}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <span className="df api-btn">
                  <Button
                    disabled={deletingFun}
                    btnname={deletingFun ? "Deleting..." : "Delete"}
                    onclick={handleDeleteClick}
                    fs={20}
                    height={41}
                    br={4}
                  />
                  <Button
                    disabled={updatingFun}
                    onclick={handleUpdateClick}
                    btnname={updatingFun ? "Updating..." : "Update"}
                    fs={20}
                    height={41}
                    br={4}
                  />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UserActions;
