import React from "react";
import { Bar } from "react-chartjs-2";
import { rem } from "../../../Components/Rem_func";
// import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  //   ChartDataLabels,
  Tooltip,
  Legend
);

const DeclineReason = () => {
  const data = {
    labels: [
      ["Delayed", "Submission"], // Word broken into two lines
      ["High", "Cost"],
      ["Not", " Convinced", "With", "Delivery", "Timeliness"],
      ["Unknown"],
      ["Incomplete", "Quote"],
    ],
    datasets: [
      {
        type: "line",
        label: "Opportunity ",
        data: [1, 0, 3, 0, 0, 0],
        borderColor: "#E0E7ED",
        borderWidth: 1.5,
        pointBackgroundColor: "#E0E7ED",
        pointBorderColor: "#E0E7ED",
        pointRadius: 5,
        fill: false,
        order: 1, // Ensures the line is drawn on top of the bars
      },
      {
        type: "bar",
        label: "Quoted Value AED",
        data: [10, 5, 4, 6, 4],
        backgroundColor: "rgba(30, 102, 65, 1)",
        hoverBackgroundColor: "rgba(31, 103, 66, 0.31)",
        barPercentage: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          color: "#A3AED0",
          align: "middle", // Aligns the tick labels cross-wise
          maxRotation: 0, // Maximum rotation for tick labels
          minRotation: 0,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          color: "#A3AED0",
          beginAtZero: true,
          callback: function (value) {
            return value + "K"; // Add 'M' after the value for millions
          },
        },
        title: {
          display: false,
          //   text: "Awarded value",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      y1: {
        grid: {
          display: false,
          drawOnChartArea: false,
        },

        title: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
        ticks: {
          display: false,
        },
      },
      x1: {
        title: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `Opportunity : ${tooltipItem.raw}%`;
            }
            return `Quoted Value: $${tooltipItem.raw}K`;
          },
        },
      },
    },
    // datalabels: {
    //   display: true,
    //   align: "end",
    //   anchor: "end",
    //   color: "#FFF",
    //   font: {
    //     size: 10,
    //     weight: "bold",
    //   },
    //   formatter: function (value) {
    //     return value + "M";
    //   },
    // },
  };

  return (
    <div className="revenue-graph df flexColumn space-between sales-home-bar">
      <div className="revenue-title df justify-center">Decline Reason</div>
      <div className="df revenue-label">
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#1E6641"
            />
          </svg>
          Quoted Value AED
        </div>
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#BEC8D0"
            />
          </svg>
          Opportunity
        </div>
      </div>
      <div
        style={{
          width: rem(290),
          height: rem(191),
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default DeclineReason;
