import React from "react";
import SalesTable from "./SalesTable";
import SalesRange from "./SalesRange";
import RevenueChart from "./RevenueGraph";
import "./Dashboard.scss";
import { rem } from "../../../Components/Rem_func";
import DeclineReason from "./DeclineReason";
import QuotedResponse from "./QuotedResponse";
import BranchBarChart from "./BranchBarChart";
import SalesBieChart from "./SalesBieChart";
import SalesHeader from "./SalesHeader";
// import UnderConstruction from "../../../Components/UnderConstruction";
const DashBoard = () => {
  return (
    <section className="df  sales-dashboard flexColumn">
      {/* <UnderConstruction /> */}
      <div className="">
        <SalesHeader />
      </div>
      <div
        className="df align-start sales-contents"
        style={{ paddingLeft: rem(11) }}
      >
        <div className="df flexColumn graph-content">
          <SalesRange />
          <div className="flexBox sales-graph" style={{ width: rem(635) }}>
            <BranchBarChart />
            <SalesBieChart />
            <DeclineReason />
            <QuotedResponse />
          </div>
        </div>
        <div className="df flexColumn sales-graph-right">
          <SalesTable />
          <RevenueChart />
        </div>
      </div>
    </section>
  );
};

export default DashBoard;
