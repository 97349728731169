import React from "react";
// import { Doughnut } from "react-chartjs-2";
// import {
//   ArrowUpward,
//   ArrowDownward,
//   AccessTime,
//   Rotate90DegreesCcw,
// } from "@mui/icons-material";
import "./FeedbackStats.scss";
import { rem } from "../../../Components/Rem_func";
const FeedbackStats = () => {
  // const chartData = (data) => ({
  //   datasets: [
  //     {
  //       data: [data, 100 - data],
  //       backgroundColor: ["#2E7D32", "#E0E0E0"], // Green and light gray colors
  //       borderWidth: 0,
  //     },
  //   ],
  // });

  // const chartOptions = {
  //   cutout: "70%", // Adjusts the inner radius of the doughnut chart
  //   responsive: true,
  //   plugins: {
  //     tooltip: { enabled: false }, // Disable tooltip
  //     legend: { display: false }, // Hide legend
  //   },
  // };

  const stats = [
    {
      data: 80,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={rem(115)}
          height={rem(113)}
          viewBox="0 0 115 113"
          fill="none"
        >
          <path
            d="M104.807 56.5C104.807 82.0984 83.8473 103 57.8066 103C31.766 103 10.8066 82.0984 10.8066 56.5C10.8066 30.9016 31.766 10 57.8066 10C83.8473 10 104.807 30.9016 104.807 56.5Z"
            stroke="#E0E7ED"
            strokeWidth="20"
          />
          <path
            d="M104.807 56C104.807 81.9574 83.764 103 57.8066 103C31.8493 103 10.8066 81.9574 10.8066 56"
            stroke="#1E6641"
            strokeWidth="20"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.14 74H59.4733V50.1833L64.8783 55.5152L67.235 53.1904L57.8066 43.8896L48.3783 53.1887L50.735 55.5135L56.14 50.1833V74ZM67.8066 42.2882H47.8066V39H67.8066V42.2882Z"
            fill="black"
          />
        </svg>
      ),
      label: "Sent",
      count: 256,
    },
    {
      data: 50,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={rem(115)}
          height={rem(113)}
          viewBox="0 0 115 113"
          fill="none"
          style={{ transform: "rotate(38deg)" }}
        >
          <path
            d="M104.807 56.5C104.807 82.0984 83.8473 103 57.8066 103C31.766 103 10.8066 82.0984 10.8066 56.5C10.8066 30.9016 31.766 10 57.8066 10C83.8473 10 104.807 30.9016 104.807 56.5Z"
            stroke="#E0E7ED"
            strokeWidth="20"
          />
          <path
            d="M104.807 56C104.807 81.9574 83.764 103 57.8066 103C31.8493 103 10.8066 81.9574 10.8066 56"
            stroke="#97D3B5"
            strokeWidth="20"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.14 74H59.4733V50.1833L64.8783 55.5152L67.235 53.1904L57.8066 43.8896L48.3783 53.1887L50.735 55.5135L56.14 50.1833V74ZM67.8066 42.2882H47.8066V39H67.8066V42.2882Z"
            fill="black"
            transform="rotate(140, 57.8066, 56)"
          />
        </svg>
      ),
      label: "Received",
      count: 200,
    },
    {
      data: 30,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={rem(116)}
          height={rem(116)}
          viewBox="0 0 116 116"
          fill="none"
        >
          <path
            d="M58.6153 10.8885C84.2105 11.2888 104.782 32.5724 104.375 58.6098C103.968 84.6473 82.7408 105.277 57.1455 104.877C31.5503 104.477 10.9789 83.1932 11.3861 57.1558C11.7932 31.1183 33.02 10.4883 58.6153 10.8885Z"
            stroke="#E0E7ED"
            strokeWidth="20"
          />
          <path
            d="M58.1142 10.8806C84.0685 11.2865 104.779 32.6555 104.374 58.6097C103.968 84.564 82.5987 105.275 56.6445 104.869"
            stroke="#558B70"
            strokeWidth="20"
          />
          <path
            d="M64.5458 58.3171H67.2197V63.3931L71.5693 65.9311L70.2323 68.2711L64.5458 64.9591V58.3171ZM66.3284 54.7171C63.9645 54.7171 61.6974 55.6653 60.0259 57.3532C58.3544 59.041 57.4153 61.3302 57.4153 63.7171C57.4153 66.1041 58.3544 68.3933 60.0259 70.0811C61.6974 71.7689 63.9645 72.7171 66.3284 72.7171C68.6923 72.7171 70.9593 71.7689 72.6309 70.0811C74.3024 68.3933 75.2414 66.1041 75.2414 63.7171C75.2414 61.3302 74.3024 59.041 72.6309 57.3532C70.9593 55.6653 68.6923 54.7171 66.3284 54.7171ZM66.3284 51.1171C69.6378 51.1171 72.8117 52.4446 75.1518 54.8076C77.492 57.1706 78.8066 60.3754 78.8066 63.7171C78.8066 67.0589 77.492 70.2637 75.1518 72.6267C72.8117 74.9896 69.6378 76.3171 66.3284 76.3171C61.3549 76.3171 57.0588 73.3651 55.0445 69.1171H37.8066V63.7171C37.8066 58.9291 47.3079 56.5171 52.0675 56.5171C53.1371 56.5171 54.4562 56.6431 55.8466 56.8771C56.9771 55.1082 58.529 53.654 60.3602 52.6477C62.1915 51.6414 64.2435 51.1151 66.3284 51.1171ZM53.8501 63.7171C53.8501 62.4571 54.0284 61.2331 54.3671 60.1171C53.6184 59.9911 52.834 59.9371 52.0675 59.9371C46.7732 59.9371 41.1936 62.5651 41.1936 63.7171V65.6971H54.0106C53.9056 65.0424 53.8519 64.3804 53.8501 63.7171ZM52.0675 40.3171C53.9586 40.3171 55.7723 41.0757 57.1095 42.426C58.4467 43.7762 59.1979 45.6076 59.1979 47.5171C59.1979 49.4267 58.4467 51.258 57.1095 52.6083C55.7723 53.9586 53.9586 54.7171 52.0675 54.7171C50.1764 54.7171 48.3627 53.9586 47.0255 52.6083C45.6883 51.258 44.9371 49.4267 44.9371 47.5171C44.9371 45.6076 45.6883 43.7762 47.0255 42.426C48.3627 41.0757 50.1764 40.3171 52.0675 40.3171ZM52.0675 43.7371C51.0747 43.7371 50.1225 44.1354 49.4205 44.8443C48.7184 45.5532 48.324 46.5146 48.324 47.5171C48.324 48.5197 48.7184 49.4811 49.4205 50.19C50.1225 50.8989 51.0747 51.2971 52.0675 51.2971C53.0603 51.2971 54.0125 50.8989 54.7146 50.19C55.4166 49.4811 55.811 48.5197 55.811 47.5171C55.811 46.5146 55.4166 45.5532 54.7146 44.8443C54.0125 44.1354 53.0603 43.7371 52.0675 43.7371Z"
            fill="black"
          />
        </svg>
      ),
      label: "Pending",
      count: 56,
    },
  ];

  return (
    <div className="feedback-stat-container">
      <p className="userM">Feedback Stats</p>
      <div className="feedback-stat">
        {stats.map((stat, index) => (
          <div key={index} className="stat-item">
            <div className="chart-container">
              {/* <Doughnut data={chartData(stat.data)} options={chartOptions} /> */}
              <div className="stat-icon">{stat.icon}</div>
            </div>
            <div className="stat">
              <p className="stat-label">{stat.label}</p>
              <p className="stat-count">{stat.count}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedbackStats;
