import React from "react";
import { Modal } from "antd";
import QualityPopUp from "./QualityPopUp";
import { rem } from "../../Components/Rem_func";
import Button from "../../Components/Button/Button";

const QualityOpen = ({ openHandler, closeHandler, mtc, generateMTC, open }) => {
  return (
    <Modal
      centered
      visible={true} // Make sure the modal is visible
      onOk={openHandler}
      onCancel={closeHandler}
      className="edit-popup"
      width={rem(1186)}
    >
      <QualityPopUp />
      {open && (
        <div className="df justify-center">
          <Button
            btnname={mtc ? "Generating..." : "Generate MTC"}
            onclick={generateMTC}
            height={49}
            width={256}
            fs={20}
            br={4}
            disabled={mtc}
          />
        </div>
      )}
    </Modal>
  );
};

export default QualityOpen;
