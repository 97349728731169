import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const AQTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  totalCount,
  defaultPageSize,
}) => {
  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || []).filter((user) => {
      return (
        (user?.reference_number || "")
          .toLowerCase()
          .includes((filteredData?.reference_number || "").toLowerCase()) &&
        (user?.sales_person_name || "")
          .toLowerCase()
          .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
        (user?.client_person_name || "")
          .toLowerCase()
          .includes((filteredData?.client_person_name || "").toLowerCase()) &&
        (user?.client_subject || "")
          .toLowerCase()
          .includes((filteredData?.client_subject || "").toLowerCase())
      );
    });
  }, [users, filteredData]);

  // Configuration for table columns
  const columns = [
    {
      title: "Reference Number",
      dataIndex: "reference_number",
      key: "reference_number",
      width: rem(124),
      className: "",
      sorter: (a, b) =>
        (a.reference_number || "").localeCompare(b.reference_number || ""),
      render: (text) => renderEmptyOrValue(text.substring(4)),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      width: rem(136),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: rem(144),
      render: (text, record) => {
        return <span className="aqo status">Awaiting quote</span>;
      },
      className: "",
    },
    {
      title: "Awaiting Quote",
      dataIndex: "client_email_time",
      key: "client_email_time",
      width: rem(114),
      render: (text) => {
        const quoteDate = new Date(text);
        const quoteAgeingDays = !isNaN(quoteDate)
          ? Math.floor((new Date() - quoteDate) / (1000 * 60 * 60 * 24))
          : null;

        return (
          <span className={`${!text ? "empty" : ""}`}>
            {text
              ? quoteAgeingDays !== null
                ? `${quoteAgeingDays} days`
                : "Empty"
              : "Empty"}
          </span>
        );
      },
      sorter: (a, b) => {
        const quoteDateA = new Date(a.client_email_time || "");
        const quoteAgeingDaysA = !isNaN(quoteDateA)
          ? Math.floor((new Date() - quoteDateA) / (1000 * 60 * 60 * 24))
          : null;

        const quoteDateB = new Date(b.client_email_time || "");
        const quoteAgeingDaysB = !isNaN(quoteDateB)
          ? Math.floor((new Date() - quoteDateB) / (1000 * 60 * 60 * 24))
          : null;

        return quoteAgeingDaysA - quoteAgeingDaysB;
      },
    },

    {
      title: "Client Team Member",
      dataIndex: "client_person_name",
      key: "client_person_name",
      width: rem(134),
      className: "noPadding",

      render: (text, record) => (
        <span className="df flexColumn align-start">
          {renderEmptyOrValue(text, false, "client-name")}

          {renderEmptyOrValue(record.client_email, false, "email")}
        </span>
      ),
      sorter: (a, b) =>
        (a.client_person_name || "").localeCompare(b.client_person_name || ""),
    },
    {
      title: "Enquiry Subject",
      dataIndex: "client_subject",
      key: "client_subject",
      width: rem(164),
      className: "",
      render: (text) => renderEmptyOrValue(text),
      sorter: (a, b) =>
        (a.client_subject || "").localeCompare(b.client_subject || ""),
    },

    {
      title: `Client Enquiry Time`,
      dataIndex: "client_email_time",
      key: "client_email_time",
      className: "",
      render: (text) => renderEmptyOrValue(text?.split(" ")[0]),
      width: rem(134),
      sorter: (a, b) =>
        new Date(a.client_email_time || "") -
        new Date(b.client_email_time || ""),
    },
    {
      title: `Sales to AI`,
      dataIndex: "sales_email_time",
      key: "sales_email_time",
      className: "",
      render: (text) => renderEmptyOrValue(text?.split(" ")[0]),
      width: rem(114),
      sorter: (a, b) =>
        new Date(a.sales_email_time || "") - new Date(b.sales_email_time || ""),
    },
    {
      title: `AI Ack`,
      dataIndex: "ack_time",
      key: "ack_time",
      className: "",
      render: (text) => renderEmptyOrValue(text?.split(" ")[0]),
      width: rem(114),
      sorter: (a, b) => new Date(a.ack_time || "") - new Date(b.ack_time || ""),
    },
  ];
  useEffect(() => {
    totalCount(filteredUsers.length); // Set total count directly
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default AQTable;
