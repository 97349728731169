import React, { useState, useEffect, useCallback } from "react";
import "../STcomponents/Filter/FilterEnquires.scss";
import { rem } from "../../../Components/Rem_func";
import Button from "../../../Components/Button/Button";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";
const DashBoardFilter = ({
  hide,
  userList,
  filteredData,
  setFilteredData,
  setFilters,
}) => {
  const access = useSelector(selectUserData);
  const [managersArray, setManagersArray] = useState([]);
  const [teamLeadsArray, setTeamLeadsArray] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [localData, setLocalData] = useState(filteredData);

  const roleTypes = useCallback(() => {
    // Function to filter users by role and who they report to
    const filterUsersByRole = (role, reportingToArray = []) => {
      return userList
        .filter(
          (user) =>
            user.role_name.toLowerCase() === role.toLowerCase() &&
            (reportingToArray.length === 0 ||
              reportingToArray.includes(user.reporting_to))
        )
        .map((user) => user.user);
    };

    const selectedManagers = localData?.managers || []; // Multiple managers
    const selectedTeamLeads = localData?.teamleads || []; // Multiple team leads

    // Filter managers, team leads, and employees dynamically based on selections
    setManagersArray(filterUsersByRole("Manager"));

    const availableTeamLeads = filterUsersByRole("Teamlead", selectedManagers); // Team leads reporting to selected managers
    setTeamLeadsArray(availableTeamLeads);

    // Show employees for selected team leads or for available team leads if no team leads are selected
    const employeesToShow =
      selectedTeamLeads.length > 0
        ? filterUsersByRole("Employee", selectedTeamLeads)
        : filterUsersByRole("Employee", availableTeamLeads);

    setEmployeesArray(employeesToShow);
  }, [userList, localData]);

  useEffect(() => {
    roleTypes();
  }, [localData, roleTypes]);

  const handleSelectChange = (name, values) => {
    setLocalData((prevData) => ({
      ...prevData,
      [name]: values,
    }));
    setFilters((prevFilter) => ({
      ...prevFilter,
      location: "",
    }));
  };

  const handleFilter = () => {
    setFilteredData(localData);
    hide();
  };
  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);
  const ResetFilter = () => {
    const resetData = {
      managers: [],
      teamleads: [],
      employees: [],
    };

    setFilteredData(resetData);
    setLocalData(resetData);
  };

  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>
      <div className="filter-body">
        {access?.role_name === "admin" && (
          <SelectSearch
            options={managersArray}
            label="Managers"
            name="managers"
            Placeholder="Select Options"
            value={localData?.managers}
            onChange={(values) => handleSelectChange("managers", values)}
            height={36}
            readOnly={false}
            multiple={true}
          />
        )}

        {["admin", "Manager"].includes(access?.role_name) && (
          <SelectSearch
            options={teamLeadsArray}
            label="Teamlead"
            name="teamleads"
            Placeholder="Select Options"
            value={localData?.teamleads}
            onChange={(values) => handleSelectChange("teamleads", values)}
            height={36}
            readOnly={false}
            multiple={true}
          />
        )}

        {["admin", "Manager", "Teamlead"].includes(access?.role_name) && (
          <SelectSearch
            options={employeesArray}
            label="Employee"
            name="employees"
            Placeholder="Select Options"
            value={localData?.employees}
            onChange={(values) => handleSelectChange("employees", values)}
            height={36}
            readOnly={false}
            multiple={true}
            // dropdownPositionProp={"top"}
          />
        )}
      </div>
      <div className="filter-btn">
        <div>
          <Button
            onclick={ResetFilter}
            btnname={"Reset"}
            cl={"#1e6641"}
            br={4}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            box_s="none"
            fs={16}
          />
        </div>
        <div>
          <Button btnname={"Apply Now"} onclick={handleFilter} fs={16} br={4} />
        </div>
      </div>
    </div>
  );
};

export default DashBoardFilter;
