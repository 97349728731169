import React from "react";
import { Bar } from "react-chartjs-2";
import { rem } from "../../../Components/Rem_func";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const RevenueChart = () => {
  const data = {
    labels: [
      "dsstechnology",
      "eisfzc.ae",
      "stsom.ae",
      "spirapower.com",
      "gmail.com",
      "galwaytr.com",
      "lamons.com",
      "kallhour.ae",
      "albanna-eng.com",
      "sealstore.net",
      "npcc.ae",
    ],
    datasets: [
      {
        type: "bar",
        label: "Awarded value",
        data: [50, 5, 4, 6, 4, 3, 6, 2, 3, 5, 4],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.bottom);
          gradient.addColorStop(1, "rgba(30, 102, 65, 1)");

          gradient.addColorStop(0.31, "rgba(31, 103, 66, 0.31)");
          return gradient;
        },
        // borderColor: "rgba(0, 128, 0, 1)",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(31, 103, 66, 0.31)",
        barPercentage: 1,
        // borderRadius: 5,
      },
      {
        type: "line",
        label: "Conversion Rate",
        data: [51, 20, 53, 0, 50, 0, 0, 0, 0, 51, 0],
        borderColor: "#878787",
        borderWidth: 2,
        pointBackgroundColor: "#878787",
        pointBorderColor: "#878787",
        pointRadius: 5,
        fill: false,
        order: 1, // Ensures the line is drawn on top of the bars
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          color: "#A3AED0",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      y: {
        grid: {
          display: false,
          color: "#A3AED0",
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "#A3AED0",
          beginAtZero: true,
          callback: function (value) {
            return value + "M"; // Add 'M' after the value for millions
          },
        },
        border: {
          display: false, // Hide the y-axis line
        },
        title: {
          display: false,
          //   text: "Awarded value",
        },
      },
      y1: {
        position: "right",
        beginAtZero: true,
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "#A3AED0",
          callback: function (value) {
            return value + "%"; // Add '%' after the value for percentage
          },
        },
        title: {
          display: false,
          //   text: "successful conversion divided by opportunity ",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `Conversion Rate: ${tooltipItem.raw}%`;
            }
            return `Awarded Value: $${tooltipItem.raw}M`;
          },
        },
      },
    },
  };

  return (
    <div className="revenue-graph df flexColumn space-between">
      <div className="revenue-title df justify-center">
        Top revenue generating companies
      </div>
      <div className="df revenue-label">
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#1E6641"
            />
          </svg>
          Awarded value
        </div>
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#BEC8D0"
            />
          </svg>
          successful conversion divided by opportunity
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: rem(275),
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default RevenueChart;
