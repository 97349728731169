import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Dashboard.scss";
// import { rem } from "../../../Components/Rem_func";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BranchBarChart = () => {
  const data = {
    labels: [["Abu", "Dhabi"], "Dubai", "Oman"],
    datasets: [
      {
        type: "bar",
        label: false,
        data: [21, 10, 3],

        // backgroundColor: "rgba(30, 130, 76, 0.6)",
        backgroundColor: "#1e6641",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.3, // Adjust bar width (smaller value increases gap)
        categoryPercentage: 0.8,
      },
      {
        type: "line",
        label: false,
        data: [3, 2, 6],

        borderColor: "#E0E7ED",
        borderWidth: 1.5,
        pointBackgroundColor: "#E0E7ED",
        pointBorderColor: "#E0E7ED",
        pointRadius: 5,
        fill: false,
        order: 1, // Ensures the line is drawn on top of the bars
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          color: "#A3AED0",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      y: {
        grid: {
          display: false,
          color: "#A3AED0",
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "#A3AED0",
          beginAtZero: true,
          callback: function (value) {
            return value + "M"; // Add 'M' after the value for millions
          },
        },
        border: {
          display: false, // Hide the y-axis line
        },
        title: {
          display: false,
          //   text: "Awarded value",
        },
      },
      y1: {
        position: "right",
        beginAtZero: true,
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
          font: {
            size: 10,
          },
          color: "#A3AED0",
          callback: function (value) {
            return value + "%"; // Add '%' after the value for percentage
          },
        },
        title: {
          display: false,
          //   text: "successful conversion divided by opportunity ",
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `Conversion Rate: ${tooltipItem.raw}%`;
            }
            return `Awarded Value: $${tooltipItem.raw}M`;
          },
        },
      },
    },
  };

  return (
    <div className="branch-bar-chart">
      <div className="barChat" style={{ height: "100%", width: "100%" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BranchBarChart;
