import React from "react";

const SalesTable = () => {
  return (
    <div className="quote_home sales-dashboard-table">
      <div className="quote-home-table">
        <table>
          <thead>
            <tr>
              {/* <th>Select rep </th> */}
              <th>Opportunity</th>
              <th>Conversation%</th>
              <th>Awarded value -AEB</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td>Graham</td> */}
              <td>1507</td>
              <td>58%</td>
              <td>12125522623</td>
            </tr>
            <tr>
              {/* <td>Azher</td> */}
              <td>1245</td>
              <td>60%</td>
              <td>1012546235</td>
            </tr>
            <tr>
              {/* <td>Mohammed</td> */}
              <td>1593</td>
              <td>70%</td>
              <td>83428332</td>
            </tr>
            <tr>
              {/* <td>Azher</td> */}
              <td>1593</td>
              <td>58%</td>
              <td>12125522623</td>
            </tr>
            <tr>
              {/* <td>Graham</td> */}
              <td>1245</td>
              <td>60%</td>
              <td>83428332</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesTable;
