import React, { useState } from "react";
import "./FilterEnquires.scss";
import Button from "../../../../Components/Button/Button";
import InputField from "../../../../Components/InputField/InputField";
import { rem } from "../../../../Components/Rem_func";

const FilterEnquires = ({ onFilter, hide }) => {
  // State to manage filtered data
  const [filteredData, setFilteredData] = useState({
    reference_number: "",
    sales_person_name: "",
    client_person_name: "",
    client_subject: "",
    reminder_status1: "",
    reminder_status2: "",
  });

  // Function to handle filter application
  const handleFilter = () => {
    onFilter(filteredData);
    hide();
  };
  // Function to reset filters
  const ResetFilter = () => {
    // Reset filtered data
    const resetData = {
      reference_number: "",
      sales_person_name: "",
      client_person_name: "",
      client_subject: "",
      reminder_status1: "",
      reminder_status2: "",
    };

    setFilteredData(resetData);

    // Apply reset data to clear filters
    onFilter(resetData);
  };

  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>

      <div className="filter-body">
        {/* Filter input fields */}

        <InputField
          label={"reference_number"}
          labelName={"Reference Number"}
          type={"text"}
          placeholder={""}
          fieldData={filteredData.reference_number}
          setFormData={setFilteredData}
          maxLength={50}
          styles={{ textTransform: "uppercase" }}
          pattern={"textwithNospace"}
        />

        <InputField
          label={"sales_person_name"}
          labelName={"Sales Team Member"}
          type={"text"}
          placeholder={""}
          fieldData={filteredData.sales_person_name}
          setFormData={setFilteredData}
          maxLength={50}
          pattern={"text"}
          // styles={{ textTransform: "capitalize" }}
        />

        <InputField
          label={"client_person_name"}
          labelName={"Client Team Member"}
          type={"text"}
          placeholder={""}
          fieldData={filteredData.client_person_name}
          setFormData={setFilteredData}
          maxLength={50}
          // styles={{ textTransform: "capitalize" }}
          pattern={"text"}
        />

        <InputField
          label={"client_subject"}
          labelName={"Subject"}
          type={"text"}
          placeholder={""}
          fieldData={filteredData.client_subject}
          setFormData={setFilteredData}
        />
      </div>
      {/* Filter buttons */}
      <div className="filter-btn">
        <div>
          <Button
            onclick={ResetFilter}
            btnname={"Reset"}
            cl={"#1e6641"}
            br={4}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            box_s="none"
            fs={16}
          />
        </div>
        <div>
          <Button btnname={"Apply Now"} onclick={handleFilter} fs={16} br={4} />
        </div>
      </div>
    </div>
  );
};

export default FilterEnquires;
