import React, { useState, useEffect, useRef } from "react";
import "./SelectField.scss";
import { rem } from "../Rem_func";

const SelectSearch = ({
  value,
  onChange,
  options,
  label,
  name,
  Placeholder,
  dropdownPositionProp,
  height,
  hasError,
  setFormData,
  readOnly,
}) => {
  const [selectedOption, setSelectedOption] = useState(value || "");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState(
    dropdownPositionProp || "bottom"
  );
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    setSelectedOption(value || "");
    setSearchValue(value || "");
  }, [value]);

  useEffect(() => {
    setSearchValue(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSearchValue(option); // Update searchValue to reflect the selected option
    onChange(option);
    setDropdownVisible(false);
  };

  const handleButtonClick = () => {
    if (!dropdownPositionProp && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Check if there is enough space below the container
      if (windowHeight - containerRect.bottom < 300) {
        setDropdownPosition("top");
      } else {
        setDropdownPosition("bottom");
      }
    }

    setDropdownVisible((prev) => !prev);
    setFilteredOptions(options);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (!options.includes(searchValue)) {
      setSearchValue(selectedOption);
    }
    setSelectedOption(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  // const handleSearchChange = (event) => {
  //   const { value } = event.target;
  //   setSearchValue(value);
  //   setDropdownVisible(true);
  //   setSelectedOption(value);
  //   if (value === "") {
  //     setSelectedOption("");
  //     onChange("");
  //   }
  //   const filtered = options.filter((option) =>
  //     option.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setFilteredOptions(filtered);
  // };

  const isDrawBorder = () => {
    const trimmedValue = searchValue?.trim();
    return (
      trimmedValue === "" ||
      !options?.some(
        (option) => option?.toLowerCase() === trimmedValue?.toLowerCase()
      )
    );
  };

  return (
    <div
      className="selectable-list-container frm-group"
      ref={containerRef}
      style={{ position: "relative" }}
    >
      {label && (
        <div className="sub-head">
          {label}
          {/* {required && <em>*</em>} */}
        </div>
      )}
      <div
        className={`frm-field ${
          (isFocused || hasError) && isDrawBorder() ? "draw-border" : ""
        }`}
        style={{
          cursor: "pointer",
          height: rem(height),
          pointerEvents: readOnly ? "none" : "auto",
        }}
        onClick={handleButtonClick}
      >
        <input
          type="text"
          placeholder={Placeholder}
          value={searchValue}
          // onChange={handleSearchChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="frm-control"
          style={{
            color: selectedOption ? "#000" : "#7B8499",
            textTransform: "capitalize",
          }}
          id={name}
          autoComplete="off"
          readOnly
        />
        <em className="slct-arw"></em>
      </div>
      <ul
        ref={dropdownRef}
        className={`uldropdown ${dropdownVisible ? "active" : ""} ${
          dropdownPosition === "top" ? "top" : ""
        }`}
        style={dropdownPosition === "top" ? { bottom: rem(height) } : {}}
      >
        {filteredOptions?.length > 0 ? (
          filteredOptions?.map((option, index) => (
            <li
              key={index}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
              style={{
                textTransform: "capitalize",
                backgroundColor: option === selectedOption ? "#f0f0f0" : "",
                color: option === selectedOption ? "#000" : "#555",
                pointerEvents: readOnly ? "none" : "auto",
              }}
            >
              {option}
            </li>
          ))
        ) : (
          <li className="dropdown-option">Option not found</li>
        )}
      </ul>
    </div>
  );
};

export default SelectSearch;
