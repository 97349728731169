import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const OPtable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  defaultPageSize,
  totalCount,
}) => {
  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || []).filter((user) => {
      return (
        (user?.reference_number || "")
          .toLowerCase()
          .includes((filteredData?.reference_number || "").toLowerCase()) &&
        (user?.sales_person_name || "")
          .toLowerCase()
          .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
        (user?.client_person_name || "")
          .toLowerCase()
          .includes((filteredData?.client_person_name || "").toLowerCase()) &&
        (user?.client_subject || "")
          .toLowerCase()
          .includes((filteredData?.client_subject || "").toLowerCase())
      );
    });
  }, [users, filteredData]);

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "Reference Number",
      dataIndex: "reference_number",
      key: "reference_number",
      width: rem(124),
      className: "",
      sorter: (a, b) =>
        (a.reference_number || "").localeCompare(b.reference_number || ""),
      render: (text) => renderEmptyOrValue(text.substring(4)),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      render: (text) => renderEmptyOrValue(text),
      width: rem(134),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: rem(134),
      render: (text, record) => {
        return <span className="op status">Order Placed</span>;
      },
      className: "",
    },
    {
      title: "Quote Value",
      dataIndex: "currency_value",
      key: "currency_value",
      sorter: (a, b) =>
        (a.currency_value || "").localeCompare(b.currency_value || ""),
      // width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },

    {
      title: "Order Value",
      dataIndex: "order_value",
      key: "order_value",
      sorter: (a, b) =>
        parseFloat(a.order_value || "") - parseFloat(b.order_value || ""),
      // width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => (a.currency || "").localeCompare(b.currency || ""),
      // width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Client Email",
      dataIndex: "client_email",
      key: "client_email",
      // width: rem(134),
      className: "",

      render: (text) => renderEmptyOrValue(text?.split(" ")[0]),
      sorter: (a, b) =>
        (a.client_email || "").localeCompare(b.client_email || ""),
    },
    {
      title: `Order Date Time`,
      dataIndex: "order_date_time",
      key: "order_date_time",
      sorter: (a, b) =>
        new Date(a.order_date_time || "") - new Date(b.order_date_time || ""),
      render: (text) => renderEmptyOrValue(text),
      width: rem(134),
    },
    {
      title: "Order Closure Days",
      dataIndex: "client_email_time",
      key: "client_email_time",
      render: (text, record) => {
        const orderDate = record.order_date_time
          ? new Date(record.order_date_time)
          : null;
        const clientEmail = record.client_email_time
          ? new Date(record.client_email_time)
          : null;

        const orderClosureDays =
          orderDate && clientEmail
            ? Math.floor((orderDate - clientEmail) / (1000 * 60 * 60 * 24))
            : null;
        return (
          <span>
            {orderClosureDays !== null ? (
              `${orderClosureDays} days`
            ) : (
              <span className="empty">Empty</span>
            )}
          </span>
        );
      },
      // width: rem(134),
    },
  ];

  useEffect(() => {
    totalCount(filteredUsers.length); // Set total count directly
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default OPtable;
