import React, { useEffect, useState } from "react";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";
import { useNavigate } from "react-router-dom";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";
import SnackBar from "../../Components/SnackBar";
import material_symbols_download from "../../Assets/QuizMobile/material_symbols_download.svg";

export default function QuizQuestions() {
  const navigate = useNavigate();
  const [questionList, setQuestionList] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isRank, setIsRank] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [showCorrectData, setShowCorrectData] = useState({});
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  // const [timeDuration, setTimeDuration] = useState(0);
  const initialTime = 30;
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    // Convert minutes to seconds
    const totalSeconds = timeLeft * 60;

    // Function to update the time left
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer); // Stop the timer when it reaches 0
          return 0;
        }
        return prevTime - 1; // Decrement time by 1 minute
      });
    }, 60000); // Update every minute

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const getBackgroundColor = () => {
    if (timeLeft <= 10) {
      return "#ff4d4d"; // Red for 10 minutes or less
    } else if (timeLeft <= 20) {
      return "#ffd700"; // Yellow for 20 minutes or less
    }
    return "#1e6641"; // Default background color
  };

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const widthPercentage = (timeLeft / initialTime) * 100;

  const poolName = sessionStorage.getItem("poolName");

  const fetchQuestionsData = async () => {
    try {
      const res = await _nonAuthHttp.post("/quiz/Get_Question", {
        pool_name: poolName,
      });
      setQuestionList(res.data.questions);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const participants_id = sessionStorage.getItem("participantsId");

  const fetchCorrectAnswer = async () => {
    try {
      const res = await _nonAuthHttp.post("/quiz/participant-answers", {
        user_id: participants_id,
      });
      setShowCorrectData(res.data);
      console.log(res.data);
      // setTimeDuration(res.data.pool.duration_in_minutes); //i am getting time duration as null
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchQuestionsData();
  }, []);

  const showAnsHandler = async () => {
    setIsloading(true);
    try {
      const res = await _nonAuthHttp.post("/quiz/submit-answers", {
        answers: Object.keys(selectedAnswers).map((questionId) => ({
          participant: participants_id,
          question: parseInt(questionId),
          chosen_choice: selectedAnswers[questionId],
          pool: questionList[0].pool,
        })),
      });
      setError("Quiz Successfully submitted");
      setToast({ submit: true });
      fetchCorrectAnswer();
      setIsRank(true);
    } catch (error) {
      console.log(error);
      setError(error?.message);
      setToast({ error: true });
    } finally {
      setIsloading(false);
    }
  };

  const handleRank = () => {
    navigate("/ranking");
  };

  const handleOptionChange = (questionId, choiceId) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: choiceId, // Store the selected choice for the question
    });
  };

  const getCorrectAnswer = (quesId) => {
    const submittedAnswer = showCorrectData.submitted_answers?.find(
      (ans) =>
        ans.question_text ===
        questionList.find((q) => q.id === quesId)?.question_text
    );
    return submittedAnswer?.correct_ANSWER;
  };

  const isAnswerCorrect = (quesId, choiceId) => {
    const correctAnswerText = getCorrectAnswer(quesId);
    const selectedChoice = questionList
      .find((q) => q.id === quesId)
      ?.choices.find((choice) => choice.id === choiceId)?.choice_text;
    return correctAnswerText === selectedChoice;
  };

  const getCorrectChoiceId = (quesId) => {
    const correctAnswerText = getCorrectAnswer(quesId);
    const correctChoice = questionList
      .find((q) => q.id === quesId)
      ?.choices.find((choice) => choice.choice_text === correctAnswerText);
    return correctChoice?.id;
  };

  return (
    <div className="quiz-questions">
      <div className="logo-img">
        <img src={logoImg} alt="error" />
      </div>
      <div className="time_left">
        <p className="timeLeft" style={{ display: isRank ? "none" : "" }}>
          Time Left
        </p>
        <div className="timesred">
          <div
            className="timeBar"
            style={{
              backgroundColor: getBackgroundColor(),
              width: `${widthPercentage}%`,
              transition: "background-color 0.5s ease",
            }}
          >
            {timeLeft} Minutes
          </div>
        </div>
      </div>
      {!isRank && <p className="ques_head">Welding Quiz 1 Questions</p>}
      {isRank && (
        <p className="ques_head_rank">Welding Quiz 1 Questions & Answer</p>
      )}
      {isRank && (
        <div className="afterRank">
          <div className="leftTimeTaken">
            <p className="time_taken">Time Taken</p>
            <div
              className="timeBar_left"
              style={{
                backgroundColor: getBackgroundColor(),
                width: `${widthPercentage}%`,
                transition: "background-color 0.5s ease",
              }}
            >
              {timeLeft} Minutes
            </div>
          </div>
          <div>
            <img src={material_symbols_download} alt="error" />
          </div>
        </div>
      )}

      {questionList.map((ques, index) => (
        <div className="mcq_ques" key={index}>
          <div className="questioning_head">
            {ques.id}. {ques.question_text}
          </div>
          {ques.choices.map((choice, index) => {
            const letterOptions = ["A", "B", "C", "D"];
            const isCorrect = isAnswerCorrect(ques.id, choice.id);
            const isSelected = selectedAnswers[ques.id] === choice.id;

            return (
              <div
                className={`questioning ${
                  isRank && (isCorrect ? "correct" : isSelected ? "wrong" : "")
                }`}
                key={choice.id}
              >
                <input
                  type="radio"
                  name={`question_${ques.id}`}
                  checked={isSelected}
                  onChange={() => handleOptionChange(ques.id, choice.id)}
                  // disabled={isRank}
                  style={{
                    accentColor:
                      isRank && isCorrect
                        ? "green"
                        : isRank && isSelected
                        ? "red"
                        : "",
                  }}
                  className="kk"
                />

                <p className="points">
                  {letterOptions[index]}. {choice.choice_text}
                </p>
              </div>
            );
          })}

          {isRank && (
            <>
              <p className="ans_head">Correct Answer</p>
              <p className="correct-answer">
                {(() => {
                  const correctChoice = questionList
                    .find((q) => q.id === ques.id)
                    ?.choices.find(
                      (choice) => choice.id === getCorrectChoiceId(ques.id)
                    );

                  const letterOptions = ["A", "B", "C", "D"]; // Array for labels

                  // Find the index of the correct choice
                  const correctIndex = questionList
                    .find((q) => q.id === ques.id)
                    ?.choices.findIndex(
                      (choice) => choice.id === getCorrectChoiceId(ques.id)
                    );

                  return correctChoice && correctIndex !== -1
                    ? `${letterOptions[correctIndex]}. ${correctChoice.choice_text}`
                    : null;
                })()}
              </p>
            </>
          )}
        </div>
      ))}

      <div className="btn_quiz">
        {!isRank && (
          <button
            className="quizBtn"
            onClick={showAnsHandler}
            disabled={loading}
          >
            {loading ? "Fetching..." : "Submit & Show Answer"}
          </button>
        )}
        {isRank && (
          <button className="quizBtn" onClick={handleRank}>
            Show Ranking
          </button>
        )}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
}
