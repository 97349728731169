import React, { useState } from "react";
import FeedbackTable from "./FeedbackTable.jsx";
import { Popover, Space } from "antd";
import down from "../../Assets/EnquiresIcon/down.svg";
import filter from "../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../Assets/EnquiresIcon/filteron.svg";
import greenDown from "../../Assets/EnquiresIcon/green_down.svg";
import "../SalesTracker/STcomponents/SalesTracker/SalesTracker.scss";
import "./Feedback.scss";
import FilterFeedback from "./FilterFeedback.jsx";
import SizeChanger from "../../Components/PageSizeChanger/SizeChanger.jsx";
const Feedback = () => {
  const [openfilter, setOpenfilter] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [filteredData, setFilteredData] = useState();
  // Function to handle filter popover visibility change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to reset filters

  // Function to handle filter criteria change
  const onFilter = (data) => {
    setFilteredData(data);
    const isFilterOn =
      data.form_date !== "" ||
      data.company_name !== "" ||
      data.client_name !== "" ||
      data.client_disignation !== "" ||
      data.telephone_number !== "" ||
      data.email_address !== "" ||
      data.other_feedback !== "" ||
      data.quality_rate !== "" ||
      data.services_experience_rate !== "" ||
      data.technical_enquires_rate !== "" ||
      data.team_communication_rate !== "" ||
      data.team_help_rate !== "" ||
      data.product_quality_punctuality_rate !== "" ||
      data.customer_statisfaction_rate !== "" ||
      data.service_provider_rate !== "" ||
      data.about_team_product_service !== "";

    setFilterOn(isFilterOn);
  };
  const [count, setCount] = useState("50");
  const [totalcount, setTotalCount] = useState("0");
  const PageCount = ["10", "20", "50", "100"];

  const items = PageCount.map((page) => ({
    key: page,
    label: (
      <div
        style={{ width: "100%" }}
        className="pageCount"
        onClick={() => setCount(page)}
      >
        {page}
      </div>
    ),
  }));
  return (
    <section>
      <div className="container1">
        <div className="container-head df">
          <p className="title">Feedback Stats</p>
          <div className="total-count-box">{totalcount}</div>
        </div>
        <div className="container-head2 df">
          <SizeChanger count={count} items={items} />
          <Popover
            trigger="click"
            open={openfilter}
            placement="bottomRight"
            onOpenChange={handleOpenChange}
            content={<FilterFeedback hide={hide} onFilter={onFilter} />}
          >
            <Space>
              <span
                className="filter-head"
                data-tooltip-id="tooltip-arrow"
                data-tooltip-content="Filter"
              >
                <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                <img src={filterOn ? greenDown : down} alt="icon" />
              </span>
            </Space>
          </Popover>
        </div>
        <FeedbackTable
          filteredData={filteredData}
          totalCount={setTotalCount}
          defaultPageSize={count}
        />
      </div>
    </section>
  );
};

export default Feedback;
